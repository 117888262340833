import React, { Component } from 'react';
import moment from 'moment';
import redirectURL from "../redirectURL";
import Logo from '../../assets/icons/logo.svg'

var images = require.context('../../assets/images/', true);

class Samplepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            showpass: false,
            username: '',
            password: '',
            isNav: false,
            show: false,
            title: '',
            onConfirm: '',
        };
    }

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }
    onSubmitHandler = async (e) => {
        e.preventDefault()
        this.setState({
            show: false,
            style: { display: 'flex' },
            style2: { display: 'none' },
        })
        let md = this.cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
        let r = md(this.state.username)
        let d = md(this.state.password)
        let params = {
            e: r,
            d: d
        }
        await redirectURL.post('/verifyUser', params)
            .then((response) => {
                if (response.data.status == "success") {
                    var decode = this.decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
                    localStorage.setItem("k", response.data.output);
                    var c = moment.parseZone().format("YYYY-MM-DD")
                    var s = moment.parseZone().format("HH:mm:ss")
                    localStorage.setItem("date", c);
                    localStorage.setItem("time", s);
                    localStorage.setItem("dept_code", "SNDG")
                    localStorage.setItem("transportercode", JSON.stringify(["ENM135"]))
                    this.setState({
                        isNav: true,
                    })
                }
                else {
                    this.setState({
                        show: true,
                        title: "Invalid Email / Password.",
                        style: { display: 'none' },
                        style2: { display: 'block' },
                    })
                }
            }).catch(err => console.log(err))
    }

    changeInputHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }

    render() {
        let style = this.state.style;
        if (localStorage.getItem("k") !== "" && localStorage.getItem("k") !== undefined && localStorage.getItem("k") !== null) {
            window.location.href = '/landingpage'
        }

        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}

                <div className="login-bg">
                    <div style={{ paddingLeft: "3rem", paddingTop: "3rem" }}>
                        <img src={Logo} className='logo-left' style={{ width: "8rem" }} />
                    </div>
                    <div className="card mt-4 mb-0 login-form">
                        <div className='login-custom'>
                            <h3 className='pb-3'>Login</h3>
                            {(this.state.show) ?
                                <div className="pb-3 alert alert-danger">{this.state.title}</div>
                                : ""}
                            <form className="theme-form" onSubmit={this.onSubmitHandler} >
                                <div className="form-group">
                                    <label className="col-form-label pt-0">Username</label>
                                    <input required type="text" name='username' className="form-control form-control-lg login-textbox" value={this.state.username} onChange={this.changeInputHandler.bind(this)} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">Password</label>
                                    <input required type={this.state.showpass ? "text" : "password"} name='password' className="form-control form-control-lg login-textbox" value={this.state.password} onChange={this.changeInputHandler.bind(this)} />
                                    {
                                        this.state.showpass ?
                                            <i className="fa fa-eye eye-icon" style={this.state.show ? { top: "60%" } : {}} onClick={() => this.setState(prev => { return { showpass: !prev.showpass } })} /> :
                                            <i className="fa fa-eye-slash eye-icon" style={this.state.show ? { top: "60%" } : {}} onClick={() => this.setState(prev => { return { showpass: !prev.showpass } })} />
                                    }
                                </div>
                                <div className="p-0 " style={{ display: "flex", justifyContent: "end" }}>
                                    <a href='/forgot-password' style={{ color: 'black' }}>Forgot Password?</a>
                                </div>
                                <div className=" login-button">
                                    <button type="submit" className="btn login-textbox" style={{ backgroundColor: "#a064ba", color: "#fff" }}>Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="page-wrapper">
                    <div className="container-fluid"> */}
                {/*login page start*/}
                {/* <div className="authentication-main" >
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={ { backgroundImage: "url("+background+")" } }>
                                        <div className="text-center">
                                            <img src={require('../../assets/images/logo-login.png')}
                                                 className="logo-login" alt=""/>
                                            <hr/>
                                            <div className="social-media">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-facebook txt-fb" aria-hidden="true"></i></li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-google-plus txt-google-plus"
                                                        aria-hidden="true"></i></li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-twitter txt-twitter" aria-hidden="true"></i>
                                                    </li>
                                                    <li className="list-inline-item"><i
                                                        className="fa fa-linkedin txt-linkedin" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h4>LOGIN</h4>
                                            <h6>Enter your Username and Password For Login or Signup</h6>
                                            <div className="card mt-4 p-4 mb-0">
                                                <form className="theme-form" action={'/'} >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Your Name</label>
                                                        <input required type="text" className="form-control form-control-lg"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input required  type="password" className="form-control form-control-lg"/>
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox"/>
                                                        <label htmlFor="checkbox1">Remember me</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3">
                                                        <div className="col-md-3">
                                                            <button type="submit" className="btn btn-secondary">LOGIN
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                {/*login page end*/}
                {/* </div>
                </div> */}
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}


export default Samplepage;
