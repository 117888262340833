
import React, { Component } from 'react';

 
const ProbableAction = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowProbableData(props);
    };
    var probable = props.data.probable_accident;
    console.log(probable,"probable");    

    return (
        <div>
            {probable == 1?
            <button onClick={handleRouteClick} className={"custom-btn label label-success "} title="View Route">
               Yes &nbsp; <i className="icofont icofont-barricade f15" style={{color:"green"}}></i> 
            </button>
            :<button onClick={handleRouteClick} className={"custom-btn label label-success "} title="View Route">
               No &nbsp; <i className="icofont icofont-barricade f15" style={{color:"gray"}} ></i> 
            </button>
            }
            
       
        </div>
    );
};

export default ProbableAction;
