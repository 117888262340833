import { useState } from 'react';
import CountUp from 'react-countup';

const MainCounters = (props) => {

    const [counterOptions, setCounterOptions] = useState([
        { label: "Consignments", value: "consignments" },
        { label: "Transit Delays", value: "transit_delays" },
        { label: "No GPS Available", value: "no_gps_data" },
        { label: "No GPS Available Lifetime", value: "no_gps_data_lifetime" },
        { label: "Overspeeding", value: "overspeeding" },
        { label: "Night Driving", value: "night_driving" },
        { label: "Stoppages", value: "stoppages" },
        { label: "Accidents", value: "accidents" },
        { label: "Trip Deviations", value: "trip_deviations" },
        { label: "Loading Delay", value: "loading_delay" },
        { label: "Unloading Delay", value: "unloading_delay" }
    ]);
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[0].value)}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="txt-info f40"><span className="counter greenfont"><CountUp end={props.data.consignments ? props.data.consignments : 0} /></span></h4>
                                </div>
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[1].value)}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={props.data.transit_delays ? props.data.transit_delays : 0} /></span></h4>
                                </div>
                                <div className="col cursorPointer">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter" onClick={e => props?.setCounter(counterOptions[2].value)}>
                                            <CountUp className="txt-danger" end={props.data.no_gps_data ? props.data.no_gps_data : 0} />
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={e => props?.setCounter(counterOptions[3].value)}>
                                            <CountUp className="txt-secondary" end={props.data.no_gps_data_lifetime ? props.data.no_gps_data_lifetime : 0} />
                                        </span>
                                    </h4>
                                </div>
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[4].value)}>
                                    <span className="f13"><i className="fa fa-tachometer f22 darkblue"></i><br /> Overspeeding</span>
                                    <h4 className="darkblue f40"><span className="counter"><CountUp end={props.data.overspeeding ? props.data.overspeeding : 0} /></span></h4>

                                </div>
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[5].value)}>
                                    <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={props.data.night_driving ? props.data.night_driving : 0} /></span></h4>

                                </div>
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[9].value)} >
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={props.data.loading_delay ? props.data.loading_delay : 0} /></span></h4>
                                </div>
                                {
                                    props?.tabName?.value == "completed" || props?.tabName?.value == "all" ?
                                        <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[10].value)} >
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                            <h4 className="mildgreen f40"><span className="counter"><CountUp end={props.data.unloading_delay ? props.data.unloading_delay : 0} /></span></h4>
                                        </div> :
                                        <></>
                                }
                                <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[6].value)}>
                                    <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={props.data.stoppages ? props.data.stoppages : 0} /></span></h4>
                                </div>
                                {/* <div className="col cursorPointer" onClick={e => props?.setCounter(counterOptions[7].value)}>
                                    <span className="f13"><i className="icofont icofont-barricade f22 txt-danger"></i><br />Probable Accident</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter">
                                            <CountUp className="txt-danger" end={props.data.accidents ? props.data.accidents : 0} />
                                        </span>
                                    </h4>
                                </div> */}
                                {/* <div className="col cursorPointer"  onClick={e => props?.setCounter(counterOptions[8].value)}>
                                    <span className="f13"><i className="icofont icofont-map-pins f22 txt-danger"></i><br />Deviated Routes</span>
                                    <h4 className="txt-secondary f40">
                                        <span className="counter">
                                            <CountUp className="txt-danger" end={props.data.trip_deviations ? props.data.trip_deviations : 0} />
                                        </span>
                                    </h4>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainCounters