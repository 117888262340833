
import React, { Component } from 'react';

 
const SegmentAction = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.changeSegmentHandler(props.data);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data);    

    return (
        <div>
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className={"custom-btn label label-success "}>
                <i className="icofont icofont-site-map f20"></i>
            </button>
            
       
        </div>
    );
};

export default SegmentAction;
