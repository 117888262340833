import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY
} from '../common/utils';

import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import redirectURL from "../redirectURL";

import ColumnDrilldownDailyTransport from './columndrilldowndailytransport.js';
import LineLoadingDashboard from './linedailyloading';
// var googleAnalytics = require("../common/googleanalytics");

export default class DailyRunningTransporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowData: [],
            rowDataExcluded: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            locations: [],
            transporters: [],
            transporter: { value: "all", label: "All" },
            location: { value: "all", label: "All" },
            dept_code: 'SNDG',
            departmentarray: [],
            scatterdepartmentarray: [],
            categories: [],
            startDate:new Date(moment.parseZone().subtract(1, "month")),
            endDate:new Date(moment.parseZone()),
            scattercategories: [],
            transporterdata: [],
            transdrilldown: [],
            transportergpsdata: [],
            transgpsdrilldown: [],
            showtranschart: 'show-m',
            showgpschart: 'show-n',
            loadcheck: true,
            gpsloadcheck: false,
            loadshow: 'show-n',
            overly: 'show-n',
            monthlydata: [],
            monthlyexpdata: [],
            chartdrildown: [],
            tripsummary: [],
            tripcolumndefs: [{
                headerName: "Truck No",
                field: "truck_no",
                pinned: "left",
                width: 100
            },
            {
                headerName: "Consignment Codes",
                field: "consignment_codes",
                pinned: "left",
                width: 120
            },
            {
                headerName: "Consigner Code",
                field: "plant_location",
                pinned: "left",
                width: 120
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                pinned: "left",
                width: 160
            },
            // {
            //     headerName: "Route ID",
            //     field: "route_id",
            //     pinned: "left",
            //     width: 100
            // },
            {
                headerName: "Gate Out",
                field: "trip_started_on",
                //pinned:"left",
                width: 180,
                valueGetter: function (params) {
                    if (params.data.trip_started_on != '' && typeof params.data.trip_started_on != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.trip_started_on)
                    }
                }
            },
            {
                headerName: "Trip Ended On",
                field: "trip_ended_on",
                //pinned:"left",
                width: 180,
                valueGetter: function (params) {
                    if (params.data.trip_ended_on != '' && typeof params.data.trip_ended_on != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.trip_ended_on)
                    }
                }
            },
            {
                headerName: "Total Distance (KM)",
                field: "total_distance",
                width: 140
            },
            {
                headerName: "Day 0 (KM)",
                field: "day_0",
                width: 100
            },
            {
                headerName: "Day 1 (KM)",
                field: "day_1",
                width: 100
            },
            {
                headerName: "Day 2 (KM)",
                field: "day_2",
                width: 100
            },
            {
                headerName: "Day 3 (KM)",
                field: "day_3",
                width: 100
            },
            {
                headerName: "Day 4 (KM)",
                field: "day_4",
                width: 100
            },
            {
                headerName: "Day 5 (KM)",
                field: "day_5"
            },
            {
                headerName: "Day 6 (KM)",
                field: "day_6",
                width: 100
            },
            {
                headerName: "Day 7 (KM)",
                field: "day_7",
                width: 100
            },
            {
                headerName: "Day 8 (KM)",
                field: "day_8",
                width: 100
            },
            {
                headerName: "Day 9 (KM)",
                field: "day_9",
                width: 100
            },
            {
                headerName: "Day 10 (KM)",
                field: "day_10",
                width: 100
            },
            {
                headerName: "Day 11 (KM)",
                field: "day_11",
                width: 100
            },
            {
                headerName: "Day 12 (KM)",
                field: "day_12",
                width: 100
            },
            {
                headerName: "Day 13 (KM)",
                field: "day_13",
                width: 100
            },
            {
                headerName: "Day 14 (KM)",
                field: "day_14",
                width: 100
            },
            {
                headerName: "Day 15 (KM)",
                field: "day_15",
                width: 100
            },
            {
                headerName: "Day 16 (KM)",
                field: "day_16",
                width: 100
            },
            {
                headerName: "Day 17 (KM)",
                field: "day_17",
                width: 100
            },
            {
                headerName: "Day 18 (KM)",
                field: "day_18",
                width: 100
            },
            {
                headerName: "Day 19 (KM)",
                field: "day_19",
                width: 100
            },
            {
                headerName: "Day 20 (KM)",
                field: "day_20",
                width: 100
            }
            ],
            defaultsdate: '',
            defaultedate: '',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            pagetitle: "Transporter Dashboard",
            eventGridAction: "gridAction",

        }
    }

    componentWillReceiveProps(newprops) {
        // this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({ eventAction: eventAction });

        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
            transporter: { "value": "all", "label": 'All' },
            location: { "value": "all", "label": 'All' }
        });
        //console.log("newprops asd ",newprops)
        var dept_code = newprops.dept_code;
        //var locations=newprops.locations;
        //var transporters=newprops.transporters;
        var preqparams = {
            dept_code: dept_code
        }
        this.setState({
            dept_code: dept_code,
            locations: [],
            transporters: []
        })
        var preqparams = {
            dept_code: dept_code
        }
        redirectURL.post("/consignments/plants", preqparams)
            .then((response) => {
                var records = response.data;
                //console.log("records ", records)
                var locations = [];
                if (records.length > 0) {
                    records.map((item) => {
                        locations.push({
                            code: item.plant_code,
                            label: item.plant_name,
                        })
                    })
                }
                this.setState({
                    locations: locations
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

        redirectURL.post("/dashboard/transportersbydept")
            .then((response) => {
                var records = response.data;
                var transporters = [];
                if (records.length > 0) {
                    records.map((item) => {
                        transporters.push({
                            code: item.transporter_code,
                            label: item.transporter_name,
                        })
                    })
                }
                this.setState({
                    transporters: transporters
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            });

        redirectURL.post("/dashboard/trucktripsummary", {
            dept_code: dept_code
        })
            .then((response) => {
                //console.log("response ",response.data)
                var recordsarr = response.data;
                var records = []
                if (recordsarr.length > 0) {
                    recordsarr.map((item) => {
                        var totl = 0
                        if (parseInt(item.day_0) != '' && parseInt(item.day_0) != 'NaN' && typeof item.day_0 != 'undefined') {
                            totl = totl + parseInt(item.day_0)
                        }
                        if (parseInt(item.day_1) != '' && parseInt(item.day_1) != 'NaN' && typeof item.day_1 != 'undefined') {
                            totl = totl + parseInt(item.day_1)
                        }
                        if (parseInt(item.day_2) != '' && parseInt(item.day_2) != 'NaN' && typeof item.day_2 != 'undefined') {
                            totl = totl + parseInt(item.day_2)
                        }
                        if (parseInt(item.day_3) != '' && parseInt(item.day_3) != 'NaN' && typeof item.day_3 != 'undefined') {
                            totl = totl + parseInt(item.day_3)
                        }
                        if (parseInt(item.day_4) != '' && parseInt(item.day_4) != 'NaN' && typeof item.day_4 != 'undefined') {
                            totl = totl + parseInt(item.day_4)
                        }
                        if (parseInt(item.day_5) != '' && parseInt(item.day_5) != 'NaN' && typeof item.day_5 != 'undefined') {
                            totl = totl + parseInt(item.day_5)
                        }
                        if (parseInt(item.day_6) != '' && parseInt(item.day_6) != 'NaN' && typeof item.day_6 != 'undefined') {
                            totl = totl + parseInt(item.day_6)
                        }
                        if (parseInt(item.day_7) != '' && parseInt(item.day_7) != 'NaN' && typeof item.day_7 != 'undefined') {
                            totl = totl + parseInt(item.day_7)
                        }
                        if (parseInt(item.day_8) != '' && parseInt(item.day_8) != 'NaN' && typeof item.day_8 != 'undefined') {
                            totl = totl + parseInt(item.day_8)
                        }
                        if (parseInt(item.day_9) != '' && parseInt(item.day_9) != 'NaN' && typeof item.day_9 != 'undefined') {
                            totl = totl + parseInt(item.day_9)
                        }
                        if (parseInt(item.day_10) != '' && parseInt(item.day_10) != 'NaN' && typeof item.day_10 != 'undefined') {
                            totl = totl + parseInt(item.day_10)
                        }
                        if (parseInt(item.day_11) != '' && parseInt(item.day_11) != 'NaN' && typeof item.day_11 != 'undefined') {
                            totl = totl + parseInt(item.day_11)
                        }
                        if (parseInt(item.day_12) != '' && parseInt(item.day_12) != 'NaN' && typeof item.day_12 != 'undefined') {
                            totl = totl + parseInt(item.day_12)
                        }
                        if (parseInt(item.day_13) != '' && parseInt(item.day_13) != 'NaN' && typeof item.day_13 != 'undefined') {
                            totl = totl + parseInt(item.day_13)
                        }
                        if (parseInt(item.day_14) != '' && parseInt(item.day_14) != 'NaN' && typeof item.day_14 != 'undefined') {
                            totl = totl + parseInt(item.day_14)
                        }
                        if (parseInt(item.day_15) != '' && parseInt(item.day_15) != 'NaN' && typeof item.day_15 != 'undefined') {
                            totl = totl + parseInt(item.day_15)
                        }
                        if (parseInt(item.day_16) != '' && parseInt(item.day_16) != 'NaN' && typeof item.day_16 != 'undefined') {
                            totl = totl + parseInt(item.day_16)
                        }
                        if (parseInt(item.day_17) != '' && parseInt(item.day_17) != 'NaN' && typeof item.day_17 != 'undefined') {
                            totl = totl + parseInt(item.day_17)
                        }
                        if (parseInt(item.day_18) != '' && parseInt(item.day_18) != 'NaN' && typeof item.day_18 != 'undefined') {
                            totl = totl + parseInt(item.day_18)
                        }
                        if (parseInt(item.day_19) != '' && parseInt(item.day_19) != 'NaN' && typeof item.day_19 != 'undefined') {
                            totl = totl + parseInt(item.day_19)
                        }
                        if (parseInt(item.day_20) != '' && parseInt(item.day_20) != 'NaN' && typeof item.day_20 != 'undefined') {
                            totl = totl + parseInt(item.day_20)
                        }
                        // console.log(item.truck_no+" === "+totl)
                        records.push({
                            _id: item._id,
                            consignment_codes: item.consignment_codes,
                            truck_no: item.truck_no,
                            consigner_code: item.consigner_code,
                            transporter_name: item.transporter_name,
                            consignee_city: item.consignee_city,
                            consignee_name: item.consignee_name,
                            consigner_code: item.consigner_code,
                            days_from_start: item.days_from_start,
                            dept_code: item.dept_code,
                            modified_date: item.modified_date,
                            plant_location: item.plant_location,
                            route_id: item.route_id,
                            status: item.status,
                            transporter_code: item.transporter_code,
                            trip_ended_on: item.trip_ended_on,
                            trip_started_on: item.trip_started_on,
                            total_distance: Math.round(totl),
                            day_0: (typeof item.day_0 != 'undefined') ? Math.round(item.day_0) : 0,
                            day_1: (typeof item.day_1 != 'undefined') ? Math.round(item.day_1) : 0,
                            day_2: (typeof item.day_2 != 'undefined') ? Math.round(item.day_2) : 0,
                            day_3: (typeof item.day_3 != 'undefined') ? Math.round(item.day_3) : 0,
                            day_4: (typeof item.day_4 != 'undefined') ? Math.round(item.day_4) : 0,
                            day_5: (typeof item.day_5 != 'undefined') ? Math.round(item.day_5) : 0,
                            day_6: (typeof item.day_6 != 'undefined') ? Math.round(item.day_6) : 0,
                            day_7: (typeof item.day_7 != 'undefined') ? Math.round(item.day_7) : 0,
                            day_8: (typeof item.day_8 != 'undefined') ? Math.round(item.day_8) : 0,
                            day_9: (typeof item.day_9 != 'undefined') ? Math.round(item.day_9) : 0,
                            day_10: (typeof item.day_10 != 'undefined') ? Math.round(item.day_10) : 0,
                            day_11: (typeof item.day_11 != 'undefined') ? Math.round(item.day_11) : 0,
                            day_12: (typeof item.day_12 != 'undefined') ? Math.round(item.day_12) : 0,
                            day_13: (typeof item.day_13 != 'undefined') ? Math.round(item.day_13) : 0,
                            day_14: (typeof item.day_14 != 'undefined') ? Math.round(item.day_14) : 0,
                            day_15: (typeof item.day_15 != 'undefined') ? Math.round(item.day_15) : 0,
                            day_16: (typeof item.day_16 != 'undefined') ? Math.round(item.day_16) : 0,
                            day_17: (typeof item.day_17 != 'undefined') ? Math.round(item.day_17) : 0,
                            day_18: (typeof item.day_18 != 'undefined') ? Math.round(item.day_18) : 0,
                            day_19: (typeof item.day_19 != 'undefined') ? Math.round(item.day_19) : 0,
                            day_20: (typeof item.day_20 != 'undefined') ? Math.round(item.day_20) : 0
                        })
                    })
                }
                this.setState({
                    tripsummary: records,
                    loadshow: 'show-n',
                    overly: 'show-n'
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

        //getPlantWiseLoadingDelayAnalysis
        //console.log(" this.state.transporter ",this.state.transporter)
        let query={
            startdate:moment.parseZone(new Date(this.state.startDate)).utcOffset("+05:30").format("YYYY-MM-DD"),
            enddate:moment.parseZone(new Date(this.state.endDate)).utcOffset("+05:30").format("YYYY-MM-DD")
        }
        redirectURL.post("/dashboard/dailyrunningtranporter", query)
            .then((response) => {
                // console.log("DASD ", response.data)
                var records = JSON.parse(response.data.route_wise_data);
                var drill_down = JSON.parse(response.data.drill_down)

                //console.log("records ", records)

                var departmentarray = [];
                var loadingarr = [];
                var gpsloadingarr = [];
                var transdrildownarr = [];
                var categories = [];
                records.forEach((item, key) => {
                    //var categories = [];
                    categories.push(item.plant_location)
                    if (item.total_distance_travelled_km > 0) {
                        loadingarr.push({
                            name: item.plant_location,
                            y: Math.round(item.total_distance_travelled_km),
                            drilldown: item.plant_location
                        });
                    }

                })
                var drill_down = drill_down.sort(GetSortDescOrder("total_distance_travelled_km"));
                drill_down = groupBy(drill_down, rdata => rdata.plant_location);

                drill_down.forEach((item, key) => {

                    var trandrilseries = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            //trandrilseries.push([itm.transporter_name,Math.round(itm.total_distance_travelled_km)])
                            if (itm.total_distance_travelled_km > 0) {
                                trandrilseries.push([itm.transporter_name, Math.round(itm.total_distance_travelled_km)])
                            }
                        })
                    }
                    transdrildownarr.push({
                        name: key,
                        id: key,
                        data: trandrilseries,
                        dataLabels: {
                            enabled: false
                        }
                    })

                })

                //console.log("transdrildownarr ",transdrildownarr)

                departmentarray.push({
                    series: loadingarr,
                    categories: categories,
                    yaxistitle: "Avg. KM/Day",
                    chartdrildown: transdrildownarr,
                })
                //console.log("departmentarray ", departmentarray)
                this.setState({
                    departmentarray: departmentarray,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    rowData: [],
                    rowDataExcluded: []
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })


    }


    // logPageView = () => {
    //     try {
    //         if (googleAnalytics.page.enableGA) {
    //             googleAnalytics.logPageView();
    //             let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    //             let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
    //             let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
    //             this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
    //         } else {
    //             console.log("GA disabled...");
    //         }
    //     } catch (error) {
    //         console.error("Error occured while logging to GA, error = ", error);
    //     }
    // }

    onSelectLocation = location => {
        this.setState(
            { location },
            () => console.log(`Location Option selected:`, this.state.location)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectTransporter = transporter => {
        this.setState(
            { transporter },
            () => console.log(`Transporter Option selected:`, this.state.transporter)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    locationoptions() {
        var items = [];
        // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if (locations.length > 0) {
            items.push({ value: 'all', label: 'All' });
            locations.forEach(item => {
                items.push({ value: item.code, label: item.label });
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions() {
        var items = []
        var transporters = this.state.transporters;
        items.push({ value: 'all', label: 'All' });
        if (transporters.length > 0) {
            transporters.forEach(item => {
                items.push({ value: item.code, label: item.label });
            })
        }
        //console.log("Transps ", items)
        return items;
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    }





    // handleStartDateChange = (event, currentDate, selectedDate) => {
    //     var edate = new Date(this.state.endDate)
    //     var d = new Date(event._d);
    //     if (d > edate) {
    //         alert('From Date should be lesser than or equal to To Date')
    //     }
    //     var mm = (d.getMonth() + 1)
    //     if (mm < 10) {
    //         var month = "0" + mm
    //     }
    //     else {
    //         var month = mm
    //     }

    //     var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    //     this.setState({
    //         startDate: startdate
    //     });
    //     //let value = event.target.value;
    //     //this.setState({'startDate':value});
    // }

    // handleEndDateChange = (event, currentDate, selectedDate) => {
    //     var sdate = new Date(this.state.startDate)
    //     var d = new Date(event._d);
    //     if (d < sdate) {
    //         alert('To Date should be greater than or equal to From Date')
    //     }
    //     var mm = (d.getMonth() + 1)
    //     if (mm < 10) {
    //         var month = "0" + mm
    //     }
    //     else {
    //         var month = mm
    //     }

    //     var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    //     this.setState({
    //         endDate: edate
    //     });
    // }
    onClickGetLoadingData(event) {
        event.preventDefault();
        // if (googleAnalytics.page.enableGA) {
        //     let pageTitle = this.state.pagetitle;
        //     let eventOptions = {
        //         "category": pageTitle,
        //         "action": this.state.eventGridAction,
        //         "label": "Filters",
        //     }
        //     googleAnalytics.logEvent(eventOptions);
        // }
        this.setState({
            loadshow: 'show-m',
            overly: "show-m",
            departmentarray: []
        })
        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var startDate = this.state.defaultsdate;
        }
        else {
            var startDate = moment.parseZone(new Date(this.state.startDate)).utcOffset("+05:30").format("YYYY-MM-DD");
        }
        if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
            var endDate = this.state.defaultedate;
        }
        else {
            var endDate = moment.parseZone(new Date(this.state.endDate)).utcOffset("+05:30").format("YYYY-MM-DD");
        }
        if (this.state.location == '' || this.state.location == null) {
            var location = [{ "value": "all", "label": "All" }];
        }
        else {
            var location = this.state.location;
        }
        if (this.state.transporter == '' || this.state.transporter == null) {
            var transporter = [{ "value": "all", "label": "All" }];
        }
        else {
            var transporter = this.state.transporter;
        }

        if (Array.isArray(location) == true) {
            var locations = location
        }
        else {
            var locations = [location]
        }
        if (Array.isArray(transporter) == true) {
            var transporters = transporter
        }
        else {
            var transporters = [transporter]
        }
        var parms = {
            startDate: startDate,
            endDate: endDate,
            location: locations,
            transporter: transporters,
            dept_code: this.state.dept_code
        }
        redirectURL.post("/dashboard/filtertrucktripsummary", parms)
            .then((response) => {
                console.log("response ", response.data)
                var recordsarr = response.data;
                var records = []
                if (recordsarr.length > 0) {
                    recordsarr.map((item) => {
                        var totl = 0
                        if (parseInt(item.day_0) != '' && parseInt(item.day_0) != 'NaN' && typeof item.day_0 != 'undefined') {
                            totl = totl + parseInt(item.day_0)
                        }
                        if (parseInt(item.day_1) != '' && parseInt(item.day_1) != 'NaN' && typeof item.day_1 != 'undefined') {
                            totl = totl + parseInt(item.day_1)
                        }
                        if (parseInt(item.day_2) != '' && parseInt(item.day_2) != 'NaN' && typeof item.day_2 != 'undefined') {
                            totl = totl + parseInt(item.day_2)
                        }
                        if (parseInt(item.day_3) != '' && parseInt(item.day_3) != 'NaN' && typeof item.day_3 != 'undefined') {
                            totl = totl + parseInt(item.day_3)
                        }
                        if (parseInt(item.day_4) != '' && parseInt(item.day_4) != 'NaN' && typeof item.day_4 != 'undefined') {
                            totl = totl + parseInt(item.day_4)
                        }
                        if (parseInt(item.day_5) != '' && parseInt(item.day_5) != 'NaN' && typeof item.day_5 != 'undefined') {
                            totl = totl + parseInt(item.day_5)
                        }
                        if (parseInt(item.day_6) != '' && parseInt(item.day_6) != 'NaN' && typeof item.day_6 != 'undefined') {
                            totl = totl + parseInt(item.day_6)
                        }
                        if (parseInt(item.day_7) != '' && parseInt(item.day_7) != 'NaN' && typeof item.day_7 != 'undefined') {
                            totl = totl + parseInt(item.day_7)
                        }
                        if (parseInt(item.day_8) != '' && parseInt(item.day_8) != 'NaN' && typeof item.day_8 != 'undefined') {
                            totl = totl + parseInt(item.day_8)
                        }
                        if (parseInt(item.day_9) != '' && parseInt(item.day_9) != 'NaN' && typeof item.day_9 != 'undefined') {
                            totl = totl + parseInt(item.day_9)
                        }
                        if (parseInt(item.day_10) != '' && parseInt(item.day_10) != 'NaN' && typeof item.day_10 != 'undefined') {
                            totl = totl + parseInt(item.day_10)
                        }
                        if (parseInt(item.day_11) != '' && parseInt(item.day_11) != 'NaN' && typeof item.day_11 != 'undefined') {
                            totl = totl + parseInt(item.day_11)
                        }
                        if (parseInt(item.day_12) != '' && parseInt(item.day_12) != 'NaN' && typeof item.day_12 != 'undefined') {
                            totl = totl + parseInt(item.day_12)
                        }
                        if (parseInt(item.day_13) != '' && parseInt(item.day_13) != 'NaN' && typeof item.day_13 != 'undefined') {
                            totl = totl + parseInt(item.day_13)
                        }
                        if (parseInt(item.day_14) != '' && parseInt(item.day_14) != 'NaN' && typeof item.day_14 != 'undefined') {
                            totl = totl + parseInt(item.day_14)
                        }
                        if (parseInt(item.day_15) != '' && parseInt(item.day_15) != 'NaN' && typeof item.day_15 != 'undefined') {
                            totl = totl + parseInt(item.day_15)
                        }
                        if (parseInt(item.day_16) != '' && parseInt(item.day_16) != 'NaN' && typeof item.day_16 != 'undefined') {
                            totl = totl + parseInt(item.day_16)
                        }
                        if (parseInt(item.day_17) != '' && parseInt(item.day_17) != 'NaN' && typeof item.day_17 != 'undefined') {
                            totl = totl + parseInt(item.day_17)
                        }
                        if (parseInt(item.day_18) != '' && parseInt(item.day_18) != 'NaN' && typeof item.day_18 != 'undefined') {
                            totl = totl + parseInt(item.day_18)
                        }
                        if (parseInt(item.day_19) != '' && parseInt(item.day_19) != 'NaN' && typeof item.day_19 != 'undefined') {
                            totl = totl + parseInt(item.day_19)
                        }
                        if (parseInt(item.day_20) != '' && parseInt(item.day_20) != 'NaN' && typeof item.day_20 != 'undefined') {
                            totl = totl + parseInt(item.day_20)
                        }
                        // console.log(item.truck_no+" === "+totl)
                        records.push({
                            _id: item._id,
                            consignment_codes: item.consignment_codes,
                            truck_no: item.truck_no,
                            consigner_code: item.consigner_code,
                            transporter_name: item.transporter_name,
                            consignee_city: item.consignee_city,
                            consignee_name: item.consignee_name,
                            consigner_code: item.consigner_code,
                            days_from_start: item.days_from_start,
                            dept_code: item.dept_code,
                            modified_date: item.modified_date,
                            plant_location: item.plant_location,
                            route_id: item.route_id,
                            status: item.status,
                            transporter_code: item.transporter_code,
                            trip_ended_on: item.trip_ended_on,
                            trip_started_on: item.trip_started_on,
                            total_distance: Math.round(totl),
                            day_0: (typeof item.day_0 != 'undefined') ? Math.round(item.day_0) : 0,
                            day_1: (typeof item.day_1 != 'undefined') ? Math.round(item.day_1) : 0,
                            day_2: (typeof item.day_2 != 'undefined') ? Math.round(item.day_2) : 0,
                            day_3: (typeof item.day_3 != 'undefined') ? Math.round(item.day_3) : 0,
                            day_4: (typeof item.day_4 != 'undefined') ? Math.round(item.day_4) : 0,
                            day_5: (typeof item.day_5 != 'undefined') ? Math.round(item.day_5) : 0,
                            day_6: (typeof item.day_6 != 'undefined') ? Math.round(item.day_6) : 0,
                            day_7: (typeof item.day_7 != 'undefined') ? Math.round(item.day_7) : 0,
                            day_8: (typeof item.day_8 != 'undefined') ? Math.round(item.day_8) : 0,
                            day_9: (typeof item.day_9 != 'undefined') ? Math.round(item.day_9) : 0,
                            day_10: (typeof item.day_10 != 'undefined') ? Math.round(item.day_10) : 0,
                            day_11: (typeof item.day_11 != 'undefined') ? Math.round(item.day_11) : 0,
                            day_12: (typeof item.day_12 != 'undefined') ? Math.round(item.day_12) : 0,
                            day_13: (typeof item.day_13 != 'undefined') ? Math.round(item.day_13) : 0,
                            day_14: (typeof item.day_14 != 'undefined') ? Math.round(item.day_14) : 0,
                            day_15: (typeof item.day_15 != 'undefined') ? Math.round(item.day_15) : 0,
                            day_16: (typeof item.day_16 != 'undefined') ? Math.round(item.day_16) : 0,
                            day_17: (typeof item.day_17 != 'undefined') ? Math.round(item.day_17) : 0,
                            day_18: (typeof item.day_18 != 'undefined') ? Math.round(item.day_18) : 0,
                            day_19: (typeof item.day_19 != 'undefined') ? Math.round(item.day_19) : 0,
                            day_20: (typeof item.day_20 != 'undefined') ? Math.round(item.day_20) : 0
                        })
                    })

                    this.setState({
                        tripsummary: records,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    });
                }
                else {

                    this.setState({
                        tripsummary: [],
                        loadshow: 'show-n',
                        overly: 'show-n'
                    });
                }
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

        var parmtrs = {
            location: locations,
            transporter: transporters,
            dept_code: this.state.dept_code,
            startdate: moment.parseZone(new Date(this.state.startDate)).utcOffset("+05:30").format("YYYY-MM-DD"),
            enddate: moment.parseZone(new Date(this.state.endDate)).utcOffset("+05:30").format("YYYY-MM-DD")
        }
        redirectURL.post("/dashboard/filterdailyrunningtranporter", parmtrs)
            .then((response) => {
                // console.log("DASD ", response.data)
                var records = JSON.parse(response.data.route_wise_data);
                var drill_down = JSON.parse(response.data.drill_down)

                console.log("records ", records)

                var departmentarray = [];
                var loadingarr = [];
                var gpsloadingarr = [];
                var transdrildownarr = [];
                var categories = [];
                records.forEach((item, key) => {
                    //var categories = [];
                    categories.push(item.plant_location)
                    if (item.total_distance_travelled_km > 0) {
                        loadingarr.push({
                            name: item.plant_location,
                            y: Math.round(item.total_distance_travelled_km),
                            drilldown: item.plant_location
                        });
                    }

                })
                var drill_down = drill_down.sort(GetSortDescOrder("total_distance_travelled_km"));
                drill_down = groupBy(drill_down, rdata => rdata.plant_location);

                drill_down.forEach((item, key) => {

                    var trandrilseries = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            if (itm.total_distance_travelled_km > 0) {
                                trandrilseries.push([itm.transporter_name, Math.round(itm.total_distance_travelled_km)])
                            }
                        })
                    }
                    transdrildownarr.push({
                        name: key,
                        id: key,
                        data: trandrilseries,
                        dataLabels: {
                            enabled: false
                        }
                    })

                })

                console.log("transdrildownarr ", transdrildownarr)

                departmentarray.push({
                    series: loadingarr,
                    categories: categories,
                    yaxistitle: "Avg. KM/Day",
                    chartdrildown: transdrildownarr,
                })
                console.log("departmentarray ", departmentarray)
                this.setState({
                    departmentarray: departmentarray,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    //rowData:[],
                    // rowDataExcluded:[]
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }


    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        //    ChartMenu.prototype.saveChart = function (e) {
        // 	   var event = {
        // 	      data: this.gridOptionsWrapper.getApi(),
        // 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
        // 	    };
        // 	   //console.log("Testing", event);
        // 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
        // 	   this.dispatchEvent(event);
        // 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

        //    };


    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }

    onChangeChartView(params) {
        // if (googleAnalytics.page.enableGA) {
        //     let pageTitle = this.state.pagetitle;
        //     let eventOptions = {
        //         "category": pageTitle,
        //         "action": this.state.eventGridAction,
        //         "label": "Change Chart View",
        //     }
        //     googleAnalytics.logEvent(eventOptions);
        // }
        if (params == 'gpsloading') {
            this.setState({
                gpsloadcheck: true,
                loadcheck: false,
                showgpschart: 'show-m',
                showtranschart: 'show-n'
            })
        }
        if (params == 'loading') {
            this.setState({
                gpsloadcheck: false,
                loadcheck: true,
                showgpschart: 'show-n',
                showtranschart: 'show-m'
            })
        }
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',

        });

    }
    render() {
        var hideinvoice = (this.state.dept_code == 'LOG-PRT') ? true : false;
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    //console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                // 	browserDatePicker: true,
                // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                // 	// provide comparator function
                // 	comparator: function(filteredDate,cellValue,secondDate) {
                // 		cellValue = cellValue.replace(/\//g,"-")
                // 		if(cellValue != "" && cellValue != " "){
                // 			cellValue = cellValue.split(" ")[0].split("-");
                // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                // 			var cellDate = new Date(cellValue);
                // 			if(filteredDate.getMonth() < 10){
                // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			else{
                // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			if(filterValue.split("-")[2].length < 2)
                // 			{
                // 				var temp = filterValue
                // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                // 			}
                // 			var filteredDate = new Date(filterValue);
                // 			cellDate = cellDate.getTime();
                // 			filteredDate = filteredDate.getTime();
                // 			// console.log(cellDate,filteredDate);
                // 			if(cellDate === filteredDate)
                // 			{
                // 				return 0;
                // 			}
                // 			if(cellDate < filteredDate){
                // 				return -1;
                // 			}
                // 			if(cellDate > filteredDate)
                // 			{
                // 				return 1;
                // 			}
                // 		}
                // 	}
                // }, 
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Trip Distance",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: " Trip Duration ( + 0.25 days)",
                field: "trip_completion_days",
                width: 150
            },
            {
                headerName: " Trip Distance Range (KM)",
                field: "standard_distance_bin",
                width: 150
            },
            {
                headerName: "Standard Avg. Distance/Day (KM)",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day (KM)",
                field: "actual_average_days",
                width: 150
            }
        ]

        var columnwithDefsExclude = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                // 	browserDatePicker: true,
                // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                // 	// provide comparator function
                // 	comparator: function(filteredDate,cellValue,secondDate) {
                // 		cellValue = cellValue.replace(/\//g,"-")
                // 		if(cellValue != "" && cellValue != " "){
                // 			cellValue = cellValue.split(" ")[0].split("-");
                // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                // 			var cellDate = new Date(cellValue);
                // 			if(filteredDate.getMonth() < 10){
                // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			else{
                // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			if(filterValue.split("-")[2].length < 2)
                // 			{
                // 				var temp = filterValue
                // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                // 			}
                // 			var filteredDate = new Date(filterValue);
                // 			cellDate = cellDate.getTime();
                // 			filteredDate = filteredDate.getTime();
                // 			// console.log(cellDate,filteredDate);
                // 			if(cellDate === filteredDate)
                // 			{
                // 				return 0;
                // 			}
                // 			if(cellDate < filteredDate){
                // 				return -1;
                // 			}
                // 			if(cellDate > filteredDate)
                // 			{
                // 				return 1;
                // 			}
                // 		}
                // 	}
                // }, 
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Trip Distance",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: " Trip Duration ( + 0.25 days)",
                field: "trip_completion_days",
                width: 150
            },
            {
                headerName: " Trip Distance Range (KM)",
                field: "standard_distance_bin",
                width: 150
            },
            {
                headerName: "Standard Avg. Distance/Day (KM)",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day (KM)",
                field: "actual_average_days",
                width: 150
            }
        ]

        return (
            <div className="row pt-20px tab-brd">
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Start Date </label>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange.bind(this)}
                            name="startDate"
                            autoComplete='off'
                            className="datepicker-custom"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>End Date </label>
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={this.handleEndDateChange.bind(this)}
                            name="endDate"
                            className="datepicker-custom"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                        <label>Location </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation.bind(this)} style={{ borderRadius: "0px" }} options={this.locationoptions()} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Transporter </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{ borderRadius: "0px" }} options={this.transporteroptions()} required />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                    </div>
                </div>

                <div className="row col-xl-12 col-lg-12">
                    <div className="row col-xl-12 col-lg-12 mb-40p">
                        {this.state.departmentarray.length > 0 ?

                            this.state.departmentarray.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <ColumnDrilldownDailyTransport
                                        title={"Daily Running Summary"}
                                        subtitle={"Avg. distance traveled by the carriers yesterday"}
                                        context={this}
                                        series={item.series}
                                        drildown={item.chartdrildown}
                                        categories={item.categories}
                                        xaxistitle={"Plants"}
                                        yaxistitle={"Avg. Distance (KM)"}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>
                            )
                            : ""}
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <h5>Daily Running Details</h5>
                        <div id="myGrid" style={{ height: '550px', width: '100%' }} className="ag-theme-balham">
                            <AgGridReact
                                columnDefs={this.state.tripcolumndefs}
                                defaultColDef={this.state.defaultColDef}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                statusBar={this.state.statusBar}
                                sideBar={false}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                groupUseEntireRow={false}
                                enableRangeSelection={true}
                                groupRowAggNodes={this.groupRowAggNodes}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                rowData={this.state.tripsummary}
                                groupHeaderHeight={this.state.groupHeaderHeight}
                                headerHeight={this.state.headerHeight}
                                floatingFiltersHeight={this.state.floatingFiltersHeight}
                                pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                                pivotHeaderHeight={this.state.pivotHeaderHeight}
                                rowHeight={this.state.rowHeight}
                                frameworkComponents={this.state.frameworkComponents}
                                popupParent={this.state.popupParent}
                                enableCharts={true}
                                processChartOptions={this.state.processChartOptions}
                                suppressChartRanges={true}

                            // detailCellRendererParams={this.state.detailCellRendererParams}
                            //masterDetail={true}
                            />
                        </div>
                    </div>
                    <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                </div>

            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


$(document).ready(function () {

    $(".loadclick").click(function () {
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#" + id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}





