// src/GroupIconRenderer.jsx
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './GroupIconRenderer.css'; // Ensure this is imported for styling

const GroupIconRenderer = (props) => {
    // const [visibleGroupID, setVisibleGroupID] = useState(null);
    // const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const iconRef = useRef(null);
    const style = {
        transit_delay: "#cccc",
        no_gps_data: "#cccc",
        enroute_exception: "#cccc",
        overspeeding_exception: "#cccc",
        nightdriving_exception: "#cccc",
        loading_delay_exception: "#cccc",
        unloading_delay_exception: "#cccc",
        consignment: "#cccc",
    }
    if (props?.data?.transit_delay != undefined && props?.data?.transit_delay == 1) {
        style["transit_delay"] = "#FF5370"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.no_gps_data != undefined && props?.data?.no_gps_data == 1) {
        style["no_gps_data"] = "#FF5370"
        style["consignment"] = "#FF5370"
    }
    else if (props?.data?.no_gps_data != undefined && props?.data?.no_gps_data == 0) {
        style["no_gps_data"] = "#26c6da"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.enroute_exception != undefined && props?.data?.enroute_exception == 1) {
        style["enroute_exception"] = "#a34518"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.overspeeding_exception != undefined && props?.data?.overspeeding_exception == 1) {
        style["overspeeding_exception"] = "#3300c2"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.nightdriving_exception != undefined && props?.data?.nightdriving_exception == 1) {
        style["nightdriving_exception"] = "#ab8ce4"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.loading_delay_exception != undefined && props?.data?.loading_delay_exception == 1) {
        style["loading_delay_exception"] = "#d3a337"
        style["consignment"] = "#FF5370"
    }

    if (props?.data?.unloading_delay_exception != undefined && props?.data?.unloading_delay_exception == 1) {
        style["unloading_delay_exception"] = "#7ad337"
        style["consignment"] = "#FF5370"
    }

    const handleGroupIconClick = (divID) => {
        props.node.setExpanded(false)
        if (divID !== null && divID !== undefined) {
            const iconPosition = document.getElementById("rw_" + divID);

            if (!iconPosition) {
                console.error("Element with ID 'rw_" + divID + "' not found");
                return;
            }

            const rect = iconPosition.getBoundingClientRect();

            props.setTooltipPosition({
                top: rect.bottom + window.scrollY, // Adjusted for better positioning
                left: rect.left + window.scrollX
            });

            props.setVisibleGroupID((prevID) => (prevID === divID ? null : divID));
        }
    };

    const handleIconClick = (iconType) => {
        switch (iconType) {
            case 'icon1':
                alert('Icon 1 clicked');
                break;
            case 'icon2':
                alert('Icon 2 clicked');
                break;
            case 'icon3':
                alert('Icon 3 clicked');
                break;
            case 'icon4':
                alert('Icon 4 clicked');
                break;
            case 'icon5':
                alert('Icon 5 clicked');
                break;
            case 'icon6':
                alert('Icon 6 clicked');
                break;
            default:
                console.log('Unknown icon type');
        }
    };

    const handleLegsClick = () => {
        // props.onClickShowConsignmentsLegsData(props);
        props.setVisibleGroupID(null);
    };
    const handleClick = () => {
        props.onShowTransitData(props)
        props.setVisibleGroupID(null);
    };
    const handleGPSClick = () => {
        props.onShowNoGPSData(props)
        props.setVisibleGroupID(null);
    };
    const handleOverspeed = () => {
        props.onShowOverspeedingData(props)
        props.setVisibleGroupID(null);
    };
    const handleNightdriving = () => {
        props.onShowNightDrivingData(props)
        props.setVisibleGroupID(null);
    };
    const handleStoppage = () => {
        props.onShowStoppagesData(props)
        props.setVisibleGroupID(null);
    };
    const handleDeviation = () => {
        props.onShowTransitData(props)
        props.setVisibleGroupID(null);
    };
    const handleLoading = () => {
        props.onShowLoadingData(props)
        props.setVisibleGroupID(null);
    };
    const handleUnloading = () => {
        props.onShowUnloadingData(props)
        props.setVisibleGroupID(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (iconRef.current && !iconRef.current.contains(event.target)) {
                props.setVisibleGroupID(null); // Close the tooltip if clicking outside
            }
        };

        // document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="icon-container" ref={iconRef} id={"rw_" + props.node.rowIndex}>
            {/* <span className="group-icon" title='Exception Flags' onClick={() => handleGroupIconClick(props.node.rowIndex)}> */}
            <span className="group-icon" title='Exception Flags'>
                <i className="fa fa-cubes" style={{ color: style.consignment }}></i>
            </span>
            {props.visibleGroupID === props.node.rowIndex && ReactDOM.createPortal(
                <div className="tooltip-icons" style={{ top: props.tooltipPosition.top, left: props.tooltipPosition.left }}>
                    {/* <span onClick={handleLegsClick}>
                        <i className="fa fa-sitemap f20"></i>
                    </span> */}
                    <span title='Transit Delay' onClick={handleClick}>
                        <i className="icofont icofont-clock-time f20" style={{ color: style.transit_delay }}></i>
                    </span>
                    <span title='No GPS Data' onClick={handleGPSClick}>
                        <i className="icofont icofont-social-google-map f20" style={{ color: style.no_gps_data }}></i>
                    </span>
                    <span title='Overspeeding' onClick={handleOverspeed}>
                        <i className="fa fa-tachometer f20" style={{ color: style.overspeeding_exception }}></i>
                    </span>
                    <span title='Nightdriving' onClick={handleNightdriving}>
                        <i className="icofont icofont-full-night f20" style={{ color: style.nightdriving_exception }}></i>
                    </span>
                    <span title='Stoppages' onClick={handleStoppage}>
                        <i className="icofont icofont-pause f20" style={{ color: style.enroute_exception }}></i>
                    </span>
                    {/* <span onClick={handleDeviation}>
                        <i className="fa fa-random f20" style={{ color: style.transit_delay }}></i>
                    </span> */}
                    <span title='Loading Delay' onClick={handleLoading}>
                        <i className="icofont icofont-truck-alt f20" style={{ color: style.loading_delay_exception }}></i>
                    </span>
                    <span title='Unloading Delay' onClick={handleUnloading}>
                        <i className="icofont icofont-truck-loaded f20" style={{ color: style.unloading_delay_exception }}></i>
                    </span>
                </div>,
                document.body
            )}
        </div>
    );
};

export default GroupIconRenderer;


