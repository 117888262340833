// import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import { AgGridReact } from 'ag-grid-react';
import { fetchGridLayout, getDecoder, getHyphenDDMMMYYYYHHMM, saveGridLayout } from '../common/utils';
import React, { Component } from 'react';
import Select from 'react-select';
import ResendConsentRenderer from './ResendConsentRenderer.js'
import EditSimTracking from './editsimtracking.js'
import AttendedButtonRenderer from './AttendedButtonRenderer';

// import _ from "lodash";
import AttendedRenderer from './AttendedRenderer';

import redirectURL from "../redirectURL";
export const validPhone = new RegExp(/^[0-9]{10}$/);
export const validMobile = new RegExp(/^[6789]\d{9}$/);


export default class SimTrack extends Component {

	constructor(props) {


		super(props);
		this.state = {
			// modules: AllModules,
			pageTitle: "SIM Tracking Numbers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData: [],
			uploadDivWidth: '0%',
			sliderTranslate: '',
			loadshow: 'show-n',
			overly: "show-n",
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			alerterrshow: 'fade',
			gridColumnState: [],
			usergridstate: [],
			user: '',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			usermanualmodal: false,
			sliderAddTransporter: "",
			transporter_name: "",
			transporter_code: "",
			rowid: "",
			utransporter_name: "",
			utransporter_code: "",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single",
			context: { componentParent: this },
			columnwithDefs : [
				{
					headerName: "Driver Name",
					field: "driver_name",
					width: 130,
					editable: false,
					resizable: true,
					filter: true,
					pinned: "left"
				},
				{
					headerName: "Driver Code",
					field: "driver_code",
					resizable: true,
					width: 130,
					editable: false,
					filter: true,
					pinned: "left"
				},
				{
					headerName: "Mobile Number",
					field: "driver_mobile_no",
					resizable: true,
					width: 160,
					editable: false,
					filter: true,
					pinned: "left"
				},
				{
					headerName: "Sim Service Provider",
					field: "sim_tracking_service_provider_name",
					resizable: true,
					width: 180,
					editable: false,
					filter: true,
					pinned: "left",
					cellRenderer: function (params) {
						if (params.data.sim_tracking_service_provider_name == "jio") {
							return "JIO"
						}
						else if (params.data.sim_tracking_service_provider_name == "non_jio") {
	
							return "NON-JIO"
						}
	
					}
				},
				{
					headerName: "Assigned Truck",
					field: "assigned_truck_no",
					resizable: true,
					width: 150,
					editable: false,
					filter: true,
					pinned: "left"
				},
				// {
				// 	headerName: "Resend Consent",
				// 	field: "resend",
				// 	resizable: true,
				// 	width: 130,
				// 	filter: false,
				// 	cellRenderer: function (params) {
				// 		var htmloption = '<button style="padding:2% 10% 5% 10%" class="btn btn-info" title="Resend Consent">Resend</button>'
				// 		return htmloption;
				// 	},
				// 	editable: false
				// },
				{
					headerName: "Resend Consent",
					field: "resend",
					resizable: true,
					width: 150,
					cellRenderer: ResendConsentRenderer
	
				},
				{
					headerName: "Action",
					field: "_id",
					resizable: true,
					width: 120,
					filter: false,
					cellRenderer: EditSimTracking,
					// editable: false
				},
				{
					headerName: "Comment",
					field: "comment",
					resizable: true,
					width: 130,
					filter: true,
					editable: true,
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: [
							'',
							'Consent message not available',
							'Consent approved - No confirmation message',
							'Consent approved success',
							'Already approved',
							'Deactivated',
							'Error in resend',
							'Others',
							'Issue in approval process'
						],
					},
				},
				{
					headerName: "Resend Consent On",
					field: "resend_on",
					resizable: true,
					width: 170,
					filter: true,
					valueGetter: function (params) {
						try {
							if (params.data.resend_on != "" && params.data.resend_on != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.resend_on);
							}
							else {
								return "";
							}
						}
						catch (e) {
	
						}
					},
					editable: false
				},
	
	
	
	
	
				{
					headerName: "Consent Status",
					field: "last_consent_status",
					resizable: true,
					width: 250,
					editable: false,
					valueGetter: function (params) {
						try {
							if (params.data.last_consent_status == 1) {
								return "SIM Tracking Not Enabled";
							}
							else if (params.data.last_consent_status == 2) {
								return "SIM Tracking Number Not Updated";
							}
							else if (params.data.last_consent_status == 3) {
								return "SIM Tracking Activated";
							}
							else if (params.data.last_consent_status == 4) {
								return "SIM Tracking Deactivated";
							}
							else if (params.data.last_consent_status == 5) {
								return "SIM Tracking Sent Consent";
							}
							else if (params.data.last_consent_status == 6) {
								return "Consent Pending";
							}
							else if (params.data.last_consent_status == 7) {
								return "SIM Tracking Consent Accepted";
							}
							else if (params.data.last_consent_status == 8) {
								return "SIM Tracking Consent Declined FirstTime";
							}
							else if (params.data.last_consent_status == 9) {
								return "SIM Tracking Enabled";
							}
							else if (params.data.last_consent_status == 10) {
								return "SIM Tracking Consent Decline dOnTrip";
							}
							else if (params.data.last_consent_status == 11) {
								return "SIM Tracking Registered";
							}
							else if (params.data.last_consent_status == 12) {
								return "SIM Tracking DeRegistered";
							}
							else if (params.data.last_consent_status == 13) {
								return "SIM Tracking Error";
							}
							else if (params.data.last_consent_status == 14) {
								return "SIM Tracking Requested Registration";
							}
							else if (params.data.last_consent_status == 15) {
								return "SIM Tracking Requested DeRegistration";
							}
							else if (params.data.last_consent_status == 16) {
								return "SIM Tracking Requested Activation";
							}
							else if (params.data.last_consent_status == 17) {
								return "SIM Tracking Requested DeActivation";
							}
							else if (params.data.last_consent_status == 18) {
								return "SIM Tracking Requested Location";
							}
							else if (params.data.last_consent_status == 19) {
								return "SIM Tracking Activation Failed";
							}
							else if (params.data.last_consent_status == 20) {
								return "SIM Tracking DeActivation Failed";
							}
							else if (params.data.last_consent_status == 21) {
								return "SIM Tracking DeRegistration Failed";
							}
							else if (params.data.last_consent_status == 22) {
								return "SIM Tracking Registration Failed";
							}
						}
						catch (e) {
						}
					},
					filter: true
				},
				{
					headerName: "Attended",
					field: "verified",
					resizable: true,
					filter: true,
					width: 150,
					editable: false,
					cellRenderer: AttendedButtonRenderer,
				},
	
	
				{
					headerName: "Attended On",
					field: "mark_as_verified_on",
					resizable: true,
					width: 150,
					filter: true,
					valueGetter: function (params) {
						try {
							if (params.data.mark_as_verified_on != "" && params.data.mark_as_verified_on != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.mark_as_verified_on);
							}
							else {
								return "";
							}
						}
						catch (e) {
	
						}
					},
					editable: false
				},
	
				{
					headerName: "Commented On",
					field: "commented_on",
					resizable: true,
					width: 150,
					filter: true,
					valueGetter: function (params) {
						try {
							if (params.data.commented_on != "" && params.data.commented_on != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.commented_on);
							}
							else {
								return "";
							}
						}
						catch (e) {
	
						}
					},
					editable: false
				},
	
				{
					headerName: "Created On",
					field: "created_on",
					resizable: true,
					width: 150,
					filter: true,
					valueGetter: function (params) {
						try {
							if (params.data.created_on != "" && params.data.created_on != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.created_on);
							}
							else {
								return "";
							}
						}
						catch (e) {
	
						}
					},
					editable: false
				},
	
				{
					headerName: "Modified On",
					field: "modified_on",
					resizable: true,
					width: 150,
					filter: true,
					valueGetter: function (params) {
						try {
							if (params.data.modified_on != "" && params.data.modified_on != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.modified_on);
							}
							else {
								return "";
							}
						}
						catch (e) {
	
						}
					},
					editable: false
				},
				// {
				// 	headerName: "JIO Status",
				// 	field: "jio_status",
				// 	resizable: true,
				// 	width: 130,
				// 	editable: false,
				// 	filter: true
				// },
				// {
				// 	headerName: "Telenity Status",
				// 	field: "telenity_status",
				// 	resizable: true,
				// 	width: 130,
				// 	editable: false,
				// 	filter: true
				// },
			],
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },


			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			sliderEditTransporter: "",

			consignee_name: "",
			consignee_code: "",
			lat: "",
			lng: "",
			for_code: "",
			driver_code: "",
			driver_name: "",
			assigned_truck_no:"",
			is_active: "",
			trucks: [],
			mobile_no: "",
			err_mobile: "",
			rowDataActive: [],
			truckNoList: [],
			filteredData: [],
			showTrucksList: "show-n",
			allrowData: [],
			sliderForceCloseTranslate: "",
			simoperator_value: "",
			resendcontent: "",
			errmsg: false,
			mobile_network: "",
			// mobile_networks: [{ label: "Airtel", value: "airtel" }, { label: "Reliance Jio", value: "jio" }, { label: "BSNL ", value: "bsnl" },{ label: "Vodafone Idea ", value: "vi" }],
			sim_tracking_service_provider: [{ label: "JIO", value: "jio" }, { label: "NON-JIO", value: "non_jio" }],
			truckslist: []
		}
		this.OnLoadDrivers = this.OnLoadDrivers.bind(this);
		// this.changeMobileNoHandler = this.changeMobileNoHandler.bind(this);
		// this.onClickEditBlock = this.onClickEditBlock.bind(this);
		// this.resetState = this.resetState.bind(this);
	}
	decipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		return encoded => encoded.match(/.{1,2}/g)
			.map(hex => parseInt(hex, 16))
			.map(applySaltToChar)
			.map(charCode => String.fromCharCode(charCode))
			.join('');
	}

	// Method to decode and set the user state
	decodeUser = () => {

	}
	// logPageView = () => {
	// 	try {
	// 		if (googleAnalytics.page.enableGA) {
	// 			googleAnalytics.logPageView();
	// 			let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
	// 			let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
	// 			let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
	// 			this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
	// 		} else {
	// 		}
	// 	} catch(error) {
	// 	}
	// }


	componentDidMount() {
		const user = getDecoder()
		this.setState({
			user: user
		})
		/*Consignments List*/
		//  this.logPageView(); // Log page view to GA
		// let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		// this.setState({eventAction: eventAction});
		redirectURL.post('/trucks')
			.then((response) => {
				var records = response.data;
				this.setState({
					trucks: records
				}, () => {
					if (this.state.trucks.length > 0) {
						this.state.trucks.map((item) => {
							items.push({
								value: item.truck_no,
								label: item.truck_no
							})
						})
						this.setState({
							truckslist: items
						}, () => {
						})
					}
				});
			})
			.catch(function (error) {
			});


		this.OnLoadDrivers();
		var items = [];
		var trucks = this.state.trucks;

	};
	OnLoadDrivers() {
		redirectURL.post('/simdrivers')
			.then((response) => {
				var records = response.data.drivers;
				var rowDataActive = records.filter((e) => e.is_active === 1);
				if (records.length > 0) {
					var trucks = records.map(function (e) {
						if (e.assigned_truck_no !== "") {
							return e.assigned_truck_no;
						}
					})
					this.setState({
						truckNoList: trucks
					})
				}
				this.setState({
					rowData: records,
					rowDataActive: rowDataActive,
					allrowData: records,
					loadshow: "show-n",
					overly: "show-n"
				});
			})
			.catch(function (error) {
			});
	}

	onShowUploadDiv = () => {
		this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
		});
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};



	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderAddTransporter: '',
			sliderEditTransporter: "",
			sliderForceCloseTranslate: "",
			driver_name: '',
			driver_code: '',
			mobile_no: '',
			assigned_truck_no: '',
			mobile_network: '',
			err_mobile: ''
		});
	}

	changeMobileNoHandler1 = (event) => {
		const value = event.target.value;

		// Validate the input to allow only digits and length of 10
		if (/^\d{0,10}$/.test(value)) {
			this.setState({ mobile_no: value, err_mobile: '' });
		} else {
			this.setState({ err_mobile: 'Please enter a valid mobile number (only digits, max 10).' });
		}
	}

	// Validation on blur
	// OnBlurMobile = () => {
	//     const { mobile_no } = this.state;
	//     if (mobile_no.length !== 10) {
	//         this.setState({ err_mobile: 'Mobile number must be exactly 10 digits.' });
	//     } else {
	//         this.setState({ err_mobile: '' });
	//     }
	// }

	changeMobileNoHandler(event) {
		const value = event.target.value;
		const re = /^[0-9\b]+$/;

		if ((value === '' || re.test(value)) && value.length <= 10) {
			this.setState({
				mobile_no: value,
				err_mobile: ""
			});
		} else {
			this.setState({
				err_mobile: "Please enter a valid mobile number"
			});
		}
	}

	OnBlurMobile(event) {
		var name = event.target.name;
		var value = event.target.value;

		const re = /^[0-9\b]+$/;

		if (value === "") {
			this.setState({ err_mobile: "Please enter mobile no", mobile_no: "" });
		}
		else {
			if ((re.test(value)) && (value.length < 10)) {
				this.setState({ err_mobile: "Invalid Mobile number", mobile_no: "" });
			}
			else if ((value.length > 10)) {
				this.setState({ err_mobile: "Invalid Mobile number", mobile_no: "" });
			}
		}

	}
	onGridReady = async params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.api;
		await fetchGridLayout(this.state.user?.email, "simtracking")
			.then(async res => {
				if (res.data.status == "success" && Array.isArray(res.data.data[0].columnState) && res.data.data[0].columnState.length > 0) {
					setTimeout(async () => {
						await params.api.applyColumnState({
							state: res.data.data[0].columnState,
							applyOrder: true,
						})
					}, 1000);
					this.setState({
						usergridstate: res.data.data[0].columnState
					})
				}
			})
			.catch(err => console.log(err))
	};


	saveGrid = async (e) => {

		let coloumnState = this.gridColumnApi.getColumnState()
		if (this.state.user != "" && this.state.user.email != undefined) {
			let temp = await saveGridLayout(this.state?.user?.email, "simtracking", coloumnState)
			if (temp.data.status == "success") {
				this.setState({
					show: true,
					basicTitle: "Grid successfully updated",
					basicType: "success",
					usergridstate: coloumnState
				})
				setTimeout(async () => {
					await this.gridApi.applyColumnState({
						state: coloumnState,
						applyOrder: true,
					})
				}, 1000);

			}
			else {
				this.setState({
					show: true,
					basicTitle: "Failed to update grid",
					basicType: "danger"
				});
			}
		}
	}

	resetGrid = async () => {
		let coloumnState = []
		if (this.state.user != "" && this.state.user.email != undefined) {
			let temp = await saveGridLayout(this.state?.user?.email, "simtracking", coloumnState)
			this.setState({
				usergridstate: coloumnState
			})
			if (temp.data.status == "success") {
				this.setState({
					show: true,
					basicTitle: "Grid successfully reset",
					basicType: "success"
				});
				this.gridApi.resetColumnState()
			}
			else {
				this.setState({
					show: true,
					basicTitle: "Failed to reset grid",
					basicType: "danger"
				});
			}
		}
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	changeVehicleNo = (e) => {
        let value = e.target.value;
        
        // Allow only alphabets and numbers and convert to uppercase
        value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        
        this.setState({ assigned_truck_no: value });
    }




	onCLickAddDriver() {
		this.setState({
			sliderAddTransporter: 'slider-translate',
			overly: "show-m"
		});
	}
	changeHandler(event) {
		var name = event.target.name;
		var value = event.target.value;
		this.setState({ [name]: value });
	}
	showMarkers(event) {


		this.setState({ simoperator_value: event.target.value })
	}
	newFilter = (filterType, selOption) => {
		this.setState({ [filterType]: selOption });
	}
	handleSubmit() {

		var param = this.state.resendcontent
		if (this.state.mobile_network != "" && this.state.mobile_network != undefined) {
			param.sim_operator = this.state.mobile_network.value
			param.assigned_truck_no = this.state.assigned_truck_no
			redirectURL.post("/resendconsent", param).then((response) => {
				if (response.data.status === "Success") {
					this.setState({
						show: true,
						basicTitle: response.data.message,
						basicType: "success",
						loadshow: "show-n",
						overly: "show-n",
						sliderForceCloseTranslate: "",
						errmsg: false
					})
					this.OnLoadDrivers();
				}
				else {
					this.setState({
						show: true,
						basicTitle: response.data.message,
						basicType: "danger",
						loadshow: "show-n",
						overly: "show-n"
					})
				}
			})

		}
		else {
			this.setState({ errmsg: true })
		}

	}
	onClickHideForForceClosure() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
		});

	}
	onClickEditForm(rowdata) {
		var row = rowdata.data;
		var cordinates = JSON.parse(row.coordinates);
		var lat = cordinates.lat;
		var lng = cordinates.lng;
		this.setState({
			uconsignee_name: row.consignee_name,
			uconsignee_code: row.consignee_code,
			ulat: lat,
			ulng: lng,
			ufor_code: row.for_code,
			uconsignee_city: row.consignee_city,
			uconsignee_addr1: row.consignee_addr1,
			uconsignee_addr2: row.consignee_addr2,
			uconsignee_addr3: row.consignee_addr3,
			rowid: row._id,
			sliderEditTransporter: 'slider-translate',
			overly: "show-m"
		})
	}
	onChangeTruck(assigned_truck_no) {
		this.setState({ assigned_truck_no });
	}
	saveDriverItem(event) {
		event.preventDefault();
		var driver_code = this.state.driver_code;
		var driver_name = this.state.driver_name;
		var mobile_no = this.state.mobile_no;
		var assigned_truck_no = this.state.assigned_truck_no

		// if (this.state.assigned_truck_no.value != "") {
		// 	var assigned_truck_no = this.state.assigned_truck_no.value;
		// }
		// else {
		// 	var assigned_truck_no = "";
		// }
		// var is_active = this.state.is_active;

		var params = {
			driver_code: driver_code,
			driver_name: driver_name,
			mobile_no: mobile_no,
			assigned_truck_no: assigned_truck_no,
			sim_operator: this.state.mobile_network.value
			// is_active:is_active
		}
		if (driver_code != "" && driver_name != "" && mobile_no != "") {
			this.setState({
				loadshow: "show-m",
				overly: "show-m"
			});
			redirectURL.post("/adddriver", params)
				.then((response) => {
					var resp = response.data
					if (response.data.status === "Success") {
						this.setState({
							show: true,
							basicTitle: response.data.message,
							basicType: "success",
							driver_code: "",
							driver_name: "",
							assigned_truck_no: "",
							is_active: 0,
							sliderAddTransporter: "",
							loadshow: "show-n",
							overly: "show-n",
							driver_code: '',
							driver_name: '',
							mobile_no: '',
							truck_no: '',
							mobile_network: { 'label': '', 'value': '' }
						})
						this.OnLoadDrivers();
					}
					else {
						this.setState({
							show: true,
							basicTitle: response.data.message,
							basicType: "danger",
							loadshow: "show-n",
							overly: "show-n"
						})
					}


				})
		}
	}
	onClickEditBlock(rownode) {
		this.setState({
			sliderEditTransporter: 'slider-translate',
			overly: "show-m",
			driver_code: rownode.data.driver_code,
			driver_name: rownode.data.driver_name,
			mobile_no: rownode.data.driver_mobile_no,
			assigned_truck_no: rownode.data.assigned_truck_no,
			is_active: rownode.data.is_active,
			rowid: rownode.data._id
		})
	}
	updateDriverItem(event) {
		event.preventDefault();
		var driver_code = this.state.driver_code;
		var driver_name = this.state.driver_name;
		var mobile_no = this.state.mobile_no;
		var assigned_truck_no = this.state.assigned_truck_no;

		// if (this.state.assigned_truck_no.value != "") {
		// 	var assigned_truck_no = this.state.assigned_truck_no.value;
		// }
		// else {
		// 	var assigned_truck_no = "";
		// }
		var is_active = this.state.is_active;

		var params = {
			driver_code: driver_code,
			driver_name: driver_name,
			mobile_no: mobile_no,
			assigned_truck_no: assigned_truck_no,
			is_active: is_active,
			rowid: this.state.rowid
			// for_code:for_code
		}
		if (driver_code != "" && driver_name != "" && mobile_no != "") {
			redirectURL.post("/updatedriver", params)
				.then((response) => {
					if (response.data.status === "success") {
						this.setState({
							show: true,
							basicTitle: "Successfully added driver",
							basicType: "success",
							driver_code: "",
							driver_name: "",
							assigned_truck_no: "",
							is_active: 0,
							sliderEditTransporter: "",
							overly: "show-n"
						})
						this.OnLoadDrivers();
					}
					else {
						this.setState({
							show: true,
							basicTitle: response.data.message,
							basicType: "danger"
						})
					}

				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "All fields are mandatory",
				basicType: "danger"
			})
		}
	}
	closeAlert = () => {
		this.setState({
			show: false
		});

	}

	onCellClicked = async (params) => {
		if (params.colDef.field === "resend") {
			this.setState({
				resendcontent: params.data,
				assigned_truck_no: params.data.assigned_truck_no
			})

			this.setState({
				// forceclosedata:params.data,
				// forceCloseRowNode : params,
				sliderForceCloseTranslate: "slider-translate-30p",
				overly: 'show-m',
				reasonforceclose: ""
			})


		}
		if (params.colDef.field == "verified") {
			// if(params.data.verified != 1)
			// {
			this.setState({
				loadshow: "show-m",
				overly: "show-m"
			})
			redirectURL.post("/verifyconsent", params.data).then((response) => {
				if (response.data.message === "Success") {
					this.setState({
						show: true,
						basicTitle: response.data.message,
						basicType: "success",
						loadshow: "show-n",
						overly: "show-n"
					})
					this.OnLoadDrivers();
				}
				else {
					this.setState({
						show: true,
						basicTitle: response.data.message,
						basicType: "danger",
						loadshow: "show-n",
						overly: "show-n"
					})
				}
			})
			// }
		}

		// if(params.colDef.field == "comment")
		// {
		// 	if(params.data.comment != "" && params.data.comment != undefined)
		// 	{
		// 		this.setState({
		// 			loadshow:"show-m",
		// 			overly:"show-m"
		// 		})
		// 		// redirectURL.post("/updateSIMTrackComment",params.data).then((response)=>{
		// 		// 	if(response.data.message == "Success")
		// 		// 	{
		// 		// 		this.setState({
		// 		// 			show:true,
		// 		// 			basicTitle:response.data.message,
		// 		// 			basicType:"success",
		// 		// 			loadshow:"show-n",
		// 		// 			overly:"show-n"
		// 		// 		})
		// 		// 		this.OnLoadDrivers();
		// 		// 	}
		// 		// 	else{
		// 		// 		this.setState({
		// 		// 			show:true,
		// 		// 			basicTitle:response.data.message,
		// 		// 			basicType:"danger",
		// 		// 			loadshow:"show-n",
		// 		// 			overly:"show-n"
		// 		// 		})
		// 		// 	}
		// 		// })
		// 	}
		// }
	}
	initalList = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList;
		dataset = dataset.slice(0, 100);
		this.setState({
			filteredData: dataset,
			showTrucksList: "show-m",
		})
	}
	handlerForm = (event) => {
		// let name = event.target.name;
		// let value = event.target.value;
		// this.setState({[name]:value});
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList;
		var filteredResult = dataset.filter(function (e) {
			if (e != "" && e != null) {
				return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
			}

		});
		filteredResult = filteredResult.slice(0, 100);
		var allrowData = this.state.allrowData;
		var rowData = allrowData.filter(function (e) {
			if (e.assigned_truck_no != "" && e.assigned_truck_no != null) {
				return e.assigned_truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
			}

		})
		this.setState({
			filteredData: filteredResult,
			rowData: rowData,
			showTrucksList: "show-m",
		})
	}
	changeGPSStatus = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		});
		var allrowData = this.state.allrowData;
		var gps_status = $("#gps_status").val();
		var rowData = [];
		if (gps_status === 1 || gps_status === "1") {
			var rowData = allrowData.filter((ed) => ed.consent_status == "ACTIVE");
		}
		if (gps_status === 2 || gps_status === "2") {
			var rowData = allrowData.filter((ed) => ed.consent_status == "NOT FOUND");
		}
		if (gps_status === 3 || gps_status === "3") {
			var rowData = allrowData.filter((ed) => ed.consent_status == "PENDING");
		}
		if (gps_status === 0 || gps_status === "0") {
			var rowData = allrowData;
		}
		$("#inputTruck").val("")
		this.setState({
			rowData: rowData,
			loadshow: "show-n",
			overly: "show-n"
		});
	}
	formHandler = async (event) => {
		this.setState({
			loadshow: 'show-m'
		})
		event.preventDefault();
		var truckNo = $("#inputTruck").val().toUpperCase();
		var params = {
		}
		if (truckNo != undefined && truckNo != "") {
			params.assigned_truck_no = truckNo;
		}
		if (this.state.user?.plant_code != undefined && this.state.user?.plant_code != "" && this.state.user?.plant_code != "undefined") {
			params.plant_code = this.state.user?.plant_code;
		}
		redirectURL.post('/simdrivers', params)
			.then((response) => {
				var records = response.data.drivers;
				this.setState({
					rowData: records,
					loadshow: "show-n",
					overly: "show-n"
				});
				this.OnLoadDrivers("")
			})
			.catch(function (error) {
				this.setState({
					loadshow: "show-n",
					overly: "show-n"
				});
			});
	}
	updateCellData(rownode) {
		if (rownode.colDef.field == "comment") {
			if (rownode.data.comment != "" && rownode.data.comment != undefined) {
				redirectURL.post("/updateSIMTrackComment", rownode.data).then((response) => {
					if (response.data.message == "Success") {
						this.setState({
							show: true,
							basicTitle: response.data.message,
							basicType: "success",
							loadshow: "show-n",
							overly: "show-n"
						})
						this.OnLoadDrivers();
					}
					else {
						this.setState({
							show: true,
							basicTitle: response.data.message,
							basicType: "danger",
							loadshow: "show-n",
							overly: "show-n"
						})
					}
				})
			}
		}
	}
	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		var listData = []
		this.state.filteredData.map(function (e) {
			listData.push(<li className="dropdown-truck-no" id={e}>{e}</li>)
		});
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>

				{/* {this.state.alert}
				<div className="row">
					<div className="row col-sm-12">
						<div className={"trucks-dropdown " + this.state.showTrucksList}>
							<ul>
								{listData}
							</ul>
						</div>
						<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
							<div className="col-xl-3 col-lg-3 form-group">
								<input type="text" style={{ height: "37px" }} name="truck_no" id="inputTruck" placeholder="Search By Vehicle No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
							</div>
							<div className="col-xl-2 col-lg-2 form-group">
								<button type="submit" className="btn btn-info">Find</button>
							</div>
							<div className="col-xl-4 col-lg-4 form-group"></div> */}




				{/* <div className="col-xl-3 col-lg-3 form-group">
								<select name="gps_status" id="gps_status" onChange={this.changeGPSStatus} className="form-control">
									<option value="">Filter By Consent Status</option>
									<option value="0">All</option>
									<option value="1">ACTIVE</option>
									<option value="2">NOT FOUND</option>
									<option value="3">PENDING</option>
								</select>
							</div> */}



				{/* </form>
					</div>
				</div> */}
				<div className="row">
					<div className="col-xl-12 col-lg-12" >
						<div className="card">
							<div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
								<h5 style={{ margin: 0 }}>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle}</span>
								</h5>

								<div className="button-group" style={{ display: "flex", justifyContent: "flex-end" }}>
									<button type="button" onClick={this.onCLickAddDriver.bind(this)} className="btn btn-warning" style={{ marginRight: "10px" }}>
										Add Driver
									</button>
									<button type="button" title="Save Grid Layout" name="savegrid" className="btn btn-success float-right action_btn" style={{ marginLeft: "10px" }} onClick={this.saveGrid}> <i className="fa fa-save"></i> </button>
									<button type="button" title="Reset Grid Layout" name="resetgrid" className="btn btn-info float-right action_btn" style={{ marginLeft: "10px" }} onClick={this.resetGrid}> <i className="fa fa-reply"></i> </button>

								</div>
							</div>


							<div className="card-body">

								<div className="row">
									<div className="col-sm-12">
										<p style={{ float: 'right', marginBottom: "10px", fontWeight: '500', fontSize: '14px', color: '#333333' }}> For Approving Consent Use The Mentioned Numbers &nbsp;
											<i style={{ color: '#333333' }} className="fa fa-arrow-right"></i>  <span style={{ color: '#e52424' }}>NON-JIO 7303777791 (IVR NO)</span> | <span style={{ color: '#3234a8' }}>JIO 9982256700 (MISSED CALL NO)</span> </p>
									</div>
								</div>

								<div id="myGrid" style={{ height: "550px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={this.state.columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// rowSelection={this.state.rowSelection}
										enableCharts={false}
										frameworkComponents={{ attendedButtonRenderer: AttendedButtonRenderer }}
										//  paginationAutoPageSize={true}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										// enableRangeSelection={true}
										onGridReady={this.onGridReady}
										deleteRowHandler={this.deleteRowHandler}
										// onSelectionChanged={this.onSelectionChanged.bind(this)}
										// frameworkComponents={this.state.frameworkComponents}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										// floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										onCellClicked={this.onCellClicked}
										//updateRowHandler={this.updateRowHandler}
										onCellEditingStopped={this.updateCellData.bind(this)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r " + (this.state.sliderAddTransporter)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Driver
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveDriverItem.bind(this)}>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Name</label>
									<input type="text" placeholder="Enter Driver Name" autoComplete='off'
										onChange={(event) => {
											let value = event.target.value;
											if (value.length > 0) {
												value = value.charAt(0).toUpperCase() + value.slice(1);
											}
											this.setState({ driver_name: value });
										}}
										value={this.state.driver_name} id="driver_name" name="driver_name" className="form-control" required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Code</label>
									<input type="text" placeholder="Enter Driver Code" autoComplete='off' onChange={this.changeHandler.bind(this)} value={this.state.driver_code} id="driver_code" name="driver_code" className="form-control" required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Mobile Number</label>
									<input
										type="text"
										placeholder="Enter Mobile Number"
										onChange={this.changeMobileNoHandler.bind(this)}
										autoComplete='off'
										onBlur={this.OnBlurMobile.bind(this)}
										value={this.state.mobile_no}
										id="mobile_no"
										name="mobile_no"
										className="form-control"
										required
									/>
									{this.state.err_mobile && (
										<span style={{ fontSize: "11px", color: "#ff0000", marginTop: "5px", display: "block" }}>
											{this.state.err_mobile}
										</span>
									)}
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Vehicle No</label>
								
									<Select
										className="col-sm-12"
										name="assigned_truck_no"
										id="assigned_truck_no"X
										onChange={this.onChangeTruck.bind(this)}
										value={this.state.assigned_truck_no}
										options={this.state.truckslist}
										required={true}
									/>
								</div> */}

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Vehicle No </label>
									<input type="text"
										placeholder="Enter Vehicle No"
										autoComplete='off'
										onChange={this.changeVehicleNo}
										value={this.state.assigned_truck_no}
										id="assigned_truck_no"
										name="assigned_truck_no"
										className="form-control"
										required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> SIM Operator</label>
									<Select
										className="col-sm-12"
										name="mobile_network"
										id="mobile_network"
										value={this.state.mobile_network}
										onChange={this.newFilter.bind(this, "mobile_network")}
										options={this.state.sim_tracking_service_provider}
										required={true}
									/>
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-5 col-lg-5"> Is Active</label>
								<div className="col-sm-7">
									<input type="radio" onChange={this.changeHandler.bind(this)} value="1" id="is_active" name="is_active" required /> Yes&nbsp;
									<input type="radio" onChange={this.changeHandler.bind(this)} value="0" id="is_active" name="is_active" required /> No
								</div>
							</div> */}

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderEditTransporter)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Edit Driver
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateDriverItem.bind(this)}>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"> Driver Name</label>
									<input type="text" placeholder="Enter Driver Name" autoComplete='off' onChange={this.changeHandler.bind(this)} value={this.state.driver_name} id="driver_name" name="driver_name" className="form-control" required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Code</label>
									<input type="text" placeholder="Enter Driver Code" autoComplete='off' onChange={this.changeHandler.bind(this)} value={this.state.driver_code} id="driver_code" name="driver_code" className="form-control" required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Mobile Number</label>
									<input
										type="text"
										placeholder="Enter Mobile Number"
										onChange={this.changeMobileNoHandler1}
										autoComplete='off'
										onBlur={this.OnBlurMobile}
										value={this.state.mobile_no}
										id="mobile_no"
										name="mobile_no"
										className="form-control"
										required
									/>
									{this.state.err_mobile && (
										<span style={{ fontSize: "11px", color: "#ff0000" }}>
											{this.state.err_mobile}
										</span>
									)}
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Vehicle No </label>
									<input type="text"
										placeholder="Enter Vehicle No"
										autoComplete='off'
										onChange={this.changeVehicleNo}
										value={this.state.assigned_truck_no}
										id="assigned_truck_no"
										name="assigned_truck_no"
										className="form-control"
										required />
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Vehicle No</label>
									<Select
										className="col-sm-12"
										name="assigned_truck_no"
										id="assigned_truck_no"
										onChange={this.onChangeTruck.bind(this)}
										value={this.state.assigned_truck_no}
										options={this.state.truckslist}
										required={true}
									/>
								</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-5 col-lg-5"> Is Active</label>
									<div className="col-sm-7">
										<input type="radio" onChange={this.changeHandler.bind(this)} value="1" checked={(this.state.is_active == 1) ? true : false} id="is_active" name="is_active" /> Yes
										<input type="radio" onChange={this.changeHandler.bind(this)} value="0" checked={(this.state.is_active == 0) ? true : false} id="is_active" name="is_active" /> No
									</div>
								</div> */}
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

						</div>
					</div>
				</div>


				{/* <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}

				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Select SIM Operator
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<div className="col-xl-12 co-xl-12">
								<div className="form-group col-xl-12 col-lg-12">
									<label> </label>
									<Select
										className="border-radius-0"
										isMulti={false}
										id="mobile_network"
										style={{ borderRadius: "0px" }}
										value={this.state.mobile_network}
										onChange={this.newFilter.bind(this, "mobile_network")}
										options={this.state.sim_tracking_service_provider}
										required={true}
										placeholder="Select"
									/>

								</div>

								<div className="form-group col-xl-12 col-lg-12">
									<button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
									{this.state.errmsg ? <p className="txt-danger">*Please select SIM Operator</p> : ""}
								</div>

							</div>

						</div>
					</div>
				</div>

			</div>

		);
	}
}