 
import React, { Component } from 'react';

 
const NonightdriveActions = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowNightDriveKpi(0);
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Night Drive" style={{color:"#ccc"}}><i className="icofont icofont-full-night"></i> &nbsp;</button>
             
        </div>
    );
};

export default NonightdriveActions;
