
// import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { WidthProvider, Responsive } from "react-grid-layout";
// import _ from "lodash";
import React, { Component } from 'react';
import AgGrid from "./AgGrid"; import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';



// import axios from 'axios';
// import Autosuggest from 'react-autosuggest';

// import Breadcrumb from '../../common/breadcrumb.component';
// import BoxRowOne from './box-row-one.component';
import DashboardCountersComponent from './defaultCounters.component.js';

// import { AgGridReact } from '@ag-grid-community/react';
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
// import HighchartsReact from 'highcharts-react-official';
import redirectURL from "../redirectURL";
import ComponentIndex from '../../common/appendComponents';
// import DateFormater from '../../layouts/dateComponent';


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS } from '../common/utils';
var moment = require('moment'); 
 
// const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Default extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: [],
			uniqueActiveConsignments: null,

			totalActiveConsignments: null,
			gpsNotTodayActiveConsignments: null,
			totalActiveConsignmentsCount: 0,
			gpsNotTodayActiveConsignmentsCount: 0,
			currentDepartmentTitle: null,
			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	//deptvalue:'all',
        	deptvalue:null,
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
  	      open: false,
  	      filterstatess:{},
  	      columnsdatas:{},
  	      rowgroupdcols:[],
  	      valuecolumns:[],
  	      pivotmodeis:false,
  	      pivotcols:[],
  	      
  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			// dateFormater:DateFormater
		},

  	      rowData: null,
  	      rowwithoutData: '',
		  rowDataForNotReportedFor15days: [],
			rowgpspacketData:null,
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"",
			  eventGridAction: "gridAction",  
			  defaulttab:"all",
			  loadshow:"show-n" ,
			  ontripwithgps:0,
			  ontripwithgpsdata:[],
			  ontripgpsna:0,
			  trucksWithGpsLocation:[],
			  atdealertruckcnt:0,
			  atdealernogps:[],
			  alldealertrucks:[],
			  rowdataatdealer:[],
			  primaryontrips:[],
			  secondaryontrips:[],  
			  usermanualmodal: false,
			  activeTrucksIn15days: []
		};
		this.onShowGridData = this.onShowGridData.bind(this);
	}
	
    componentDidMount(){
		this.logPageView(); // Log page view to GA
		// let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		// this.setState({eventAction: eventAction});
		var pagetitle=''
		if(window.location == "/snddefault")
		{
			pagetitle = "GPS Integration Status (Sales and Dispatch)"
		}
		else if(window.location == "/prtdefault")
		{
			pagetitle = "GPS Integration Status (Spare Parts)"
		}
			
		else if(window.location == "/tnpdefault")
		{
			pagetitle = "GPS Integration Status (Train and Production)"
		}
		
		else if(window.location == "/scdefault")
		{
			pagetitle = "GPS Integration Status (Supply Chain)"
		}
		else {
			
			pagetitle = "GPS Integration Status"
		}

		// Active consignments are not applicable for LOG-SC
		let currentDepartmentCode = this.getCurrentDepartmentCode(window.location.pathname);
		
		this.filterByDepartment();
	}
	

	logPageView = () => {
		try {
		    // if (googleAnalytics.page.enableGA) {
		    //     googleAnalytics.logPageView();
		    //     let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		    //     let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		    //     let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		    //     this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    // } else {
		    //     console.log("GA disabled...");
		    // }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
 onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.api;


	
 };
 
onChartOptionsChanged(event) {
	  //console.log("where is this", event)
	    var chartOptions = event.chartOptions;
	    //savedLegendUserPreference = chartOptions.legend;
	   // savedUserPreferenceByChartType[event.chartType] = chartOptions;
}
onFirstDataRendered(params) {
    var createRangeChartParams = {
      cellRange: {
        columns: ["country", "sugar", "fat", "weight"]
      },
      chartContainer: document.querySelector("#myChart"),
      chartType: "bubble",
      suppressChartRanges: true
    };
   // currentChartRef = params.api.createRangeChart(createRangeChartParams);
}
  
 onGridState = () =>{
	 //console.log(this.gridApi);
	
	 /*Get  Current Columns State and Store in this.colState */
	   this.colState = this.gridColumnApi.getColumnState();

	 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
	   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

	 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
	  * User should enable the Pivot mode.*/
	   this.pivotColumns = this.gridColumnApi.getPivotColumns();
	 		   
	 /*Get Current Filter State and Store in window.filterState */
	   window.filterState = this.gridApi.getFilterModel();
	   this.gridApi.setFilterModel(window.filterState);
	    
	  
	   
 }

    onScatterHover(point) {     
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];       
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
    } 
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
	  }
	  
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/snddefault":
				departmentCode='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/prtdefault":
				departmentCode='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/tnpdefault":
				departmentCode='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/scdefault":
				departmentCode='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				departmentName = " default ";
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			deptvalue:departmentCode,
			currentDepartmentTitle:departmentName,
		});
		return departmentCode;
	}
    filterByDepartment = async () => {
		this.setState({
			// loadshow:'show-m'
		})
    	//console.log("val is : ", this.state.deptvalue);
    	// if(this.state.deptvalue == 'all')
		// {
    	// 	window.location.reload();
		// }
		let currentDepartmentCode = this.getCurrentDepartmentCode(window.location.pathname);
		
		if(currentDepartmentCode == 'SNDG')
		{
			var defaulttab = this.state.defaulttab
		}
		else{
			var defaulttab = ""
		}
		
		var israil = 0;
		if(window.location == "/raildefault")
		{
			israil=1;
			var defaulttab = this.state.defaulttab
		}
		
		let layoutdata={
			token:window.atob(localStorage.getItem('_t')),
			israil:israil,
			defaulttab:defaulttab
		}
		if (currentDepartmentCode) {
			layoutdata={
				token:window.atob(localStorage.getItem('_t')),
				deptcode:currentDepartmentCode,
				defaulttab:defaulttab,
				israil:israil
			}
		}
		this.setState({ deptvalue: currentDepartmentCode});
		//console.log("STATE = ", this.state);
		//console.log("IN filterByDepartment, deptvalue and currentDepartmentCode= ", this.state.deptvalue, currentDepartmentCode);
    	

		//if(this.state.deptvalue == 'SNDG')
		if(currentDepartmentCode == 'SNDG')
		{
			this.setState({
				sndshow:'show-m',
				departmentchrtshow:'show-n'
			});
		}
		else{
			this.setState({
				sndshow:'show-n',
				departmentchrtshow:'show-m'
			});
		}
    	
		

		let activeConsignmentsLayoutData = {
			token:window.atob(localStorage.getItem('_t')),
			dept_code:"SNDG",
			defaulttab:defaulttab,
			israil:israil
		}
		let totalTrucks = []
		if(window.location == "/raildefault")
		{
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			redirectURL.post('/getrailtrucks', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
			
				
				let totalTrucks = (result.uniqueTrucks) ? result.uniqueTrucks : [];
				let totalTrucksCount = (result.uniqueTrucks) ? result.uniqueTrucks.length : 0;

				let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
				let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

				//console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
				
				//totalActiveConsignmentsCount
				
				this.setState({
					totalTrucks: totalTrucks,
					truckscnt: totalTrucksCount,
					uniqueActiveConsignments: uniqueActiveConsignments,
					totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
					//loadshow:'show-n'
				});

			
			})
			.catch(function (error) {
				console.log(error);
			});


			
			/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/getrailtruckswithgpsdata', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			}) 
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;

				let trucksWithGpsLocation = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation : [];
				let trucksWithGpsLocationCount = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation.length : 0;
				var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
				let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
					if (truck.timestamp) {
						let result = false;
						let consignmentDate = truck.timestamp;
						let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
						let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
						var s1 = moment.parseZone(consignmentDateOnly).format("x")
						var s2 = moment.parseZone(todayDateOnly).format("x")
						// if (consignmentDateOnly !== todayDateOnly) {
						// 	result = true;
						// }
						
						// console.log("consignmentDateOnly s1", s1)
						// 	console.log("todayDateOnly s1", s2)
						// if(s1<s2)
						// {
							result = true;
						//} 
						return result;

					} else {
						//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
					}
				});
				
				//console.log("gpsDataNotReportedToday")
				let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
				let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;
				
				
				

				this.setState({
					trucklocations: trucksWithGpsLocation.length,
					trucksWithGpsLocation:trucksWithGpsLocation,
					ontripwithgps:ontripwithgps.length
					
				});

				
			})
			.catch(function (error) {
				console.log(error);
			});

		}
		else{
			// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
			var check = redirectURL.post('/gettrucks', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				
				totalTrucks = (result.uniqueTrucks) ? result.uniqueTrucks : [];
				console.log(totalTrucks, "totalTrucks")
				let totalTrucksCount = (result.uniqueTrucks) ? result.uniqueTrucks.length : 0;

				let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
				let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

				//console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
				
				//totalActiveConsignmentsCount
				//console.log("uniqueActiveConsignmentsCount ", uniqueActiveConsignmentsCount)
				this.setState({
					totalTrucks: totalTrucks,
					truckscnt: totalTrucksCount,
					uniqueActiveConsignments: uniqueActiveConsignments,
					totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
					//loadshow:'show-n'
				});
				this.onShowGridData();
				
			})
			.catch(function (error) {
				console.log(error);
			});
			
			/* get trucks loaded on or before 15 days */
			Promise.all([check]).then(()=>{
				redirectURL.post('/getTripsWithin15Days', activeConsignmentsLayoutData,{
					headers:{
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
					}
				}).then((response)=>{
					
					console.log(response.data, "response.data")
					// let intersection = totalTrucks.filter(x => response.data.includes(x.truck_no));
					// console.log(intersection, "intersection")
					this.setState({
						activeTrucksIn15days: response.data
					})
					/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/gettruckswithgpsdata', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			}) 
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				let trucksWithGpsLocation = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation : [];
				let trucksWithGpsLocationCount = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation.length : 0;
				var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
				let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
					if (truck.timestamp) {
						let result = false;
						let consignmentDate = truck.timestamp;
						let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
						let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
						var s1 = moment.parseZone(consignmentDateOnly).format("x")
						var s2 = moment.parseZone(todayDateOnly).format("x")
						
							result = true;
						//} 
						return result;

					} else {
						//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
					}
				});
				
				//console.log("gpsDataNotReportedToday")
				let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
				let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;
				
				
				// let gpsDataNotReportedTodayOnTripTrucks = [];
				// if (this.state.uniqueActiveConsignments && gpsDataNotReportedToday) {
				// 	gpsDataNotReportedTodayOnTripTrucks = this.state.uniqueActiveConsignments.filter(consignment => {
				// 		gpsDataNotReportedToday.forEach(item => {
				// 			return consignment['truck_no'] == item.truck_no;
				// 		})
						
				// 	})

				// }

				//console.log("ontripwithgps ", JSON.stringify(ontripwithgps))

				this.setState({
					trucklocations: trucksWithGpsLocation.length,
					ontripwithgpsdata:ontripwithgps,
					ontripwithgps:ontripwithgps.length,
					trucksWithGpsLocation:trucksWithGpsLocation,
					// loadshow:'show-n',
					atdealertruckcnt:result.totaldealertrucks,
					atdealernogps:result.atdealernogps,
					alldealertrucks:result.alldealertrucks
					
					
				});
				this.onShowGridData();
				// let gpsDataNotReportedToday = []; // for a while
				// this.setState({
				// 	totalActiveConsignments: records,
				// 	gpsNotTodayActiveConsignments: gpsDataNotReportedToday,
				// 	totalActiveConsignmentsCount: records.uniqueTrucks.length,
				// 	gpsNotTodayActiveConsignmentsCount: gpsDataNotReportedToday.length,
				// });
			})
			.catch(function (error) {
				console.log(error);
			});
				}).catch(function (error) {
					console.log(error);
				});
	
			})
			
			
		}
		
		if(currentDepartmentCode == 'LOG-SC')
		{
			//// Counter for "Trucks with GPS Data"
			redirectURL.post('/getSCData', activeConsignmentsLayoutData)
			.then((response) => {
			//	console.log("Response SC ", response.data)
				var ontriptrucks = response.data[0].ontriptrucks
				var nogpslogsc = response.data[0].nogpslogsc
				this.setState({
					totalActiveConsignmentsCount:ontriptrucks.length,
					gpsNotTodayActiveConsignments: nogpslogsc,
					gpsNotTodayActiveConsignmentsCount:nogpslogsc.length
				})
			})
			.catch(function(e){

			})

		}
		else{

			if(window.location == "/raildefault")
			{
				redirectURL.post('/getrailGpsDataNotReportedTodayOnTripTrucks', activeConsignmentsLayoutData, {
					headers:{
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
					}
				})
				.then((response) => {
					let result = JSON.parse(JSON.stringify(response)).data;
					//console.log("result = ", result);
					let trucksWithGpsLocationNotToday = (result.trucksWithGpsLocationNotToday) ? result.trucksWithGpsLocationNotToday : [];
					let trucksWithGpsLocationNotTodayCount = (result.trucksWithGpsLocationNotToday) ? result.trucksWithGpsLocationNotToday.length : 0;
					let ontripgpsna = (result.ontripgpsna) ? result.ontripgpsna : [];

					this.setState({
						gpsNotTodayActiveConsignments: trucksWithGpsLocationNotToday,
						gpsNotTodayActiveConsignmentsCount: trucksWithGpsLocationNotTodayCount,
						ontripgpsna:ontripgpsna
						
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			}
			
			
		}
		
		redirectURL.post('/trucksWithNoTransportersDataCount', layoutdata)
 		  .then((response) => {
 			 
 		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 		  // console.log("Truck GPS",trucks);
 		   this.setState({
 			 notrans:trucks
 		   });
 		   
 		    
 		  })
 		  .catch(function (error) {
 		    console.log(error);
 		  });	
   		
   		 
   		/*Truck with gpsdata*/	
		   redirectURL.post('/truckWithGPSData', layoutdata)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		   this.setState({
			   rowData:records
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
    	
	}

	onShowGridData = async () =>{
		this.setState({
			rowwithoutData:'',
			rowDataForNotReportedFor15days: []
		});
		try{
			var totalTrucks = this.state.totalTrucks;
			if(totalTrucks.length > 0)
			{
				
				var trucksWithGpsLocation = this.state.trucksWithGpsLocation;
				var result3 = []
				var rowDataForNotReportedFor15days = []
				if(trucksWithGpsLocation.length > 0)
				{
					totalTrucks.map((e)=>{
						if(trucksWithGpsLocation.filter(k => k.truck_no == e.truck_no).length == 0)
						{
							result3.push(e);
							// console.log(e.truck_no, this.state.activeTrucksIn15days.filter(j => j == e.truck_no))
							// if(this.state.activeTrucksIn15days.includes(e.truck_no) == false){
							// 	rowDataForNotReportedFor15days.push(e)
							// }
						}
						else{
							console.log('this.state.activeTrucksIn15days',this.state.activeTrucksIn15days.length)
							if(this.state.activeTrucksIn15days.includes(e.truck_no)){
								rowDataForNotReportedFor15days.push(e)
							}
						}
					})
				}
				this.setState({
					rowwithoutData: result3,
					rowDataForNotReportedFor15days: rowDataForNotReportedFor15days
				});
			}

			if(window.location != "/raildefault")
			{
				var uniqueActiveConsignments = this.state.uniqueActiveConsignments
				var ontripwithgpsdata = this.state.ontripwithgpsdata

				 //console.log("uniqueActiveConsignments ", JSON.stringify(uniqueActiveConsignments))
				// console.log("ontripwithgpsdata ", ontripwithgpsdata)

				var ontripnoresult =await uniqueActiveConsignments.filter(f =>
					!ontripwithgpsdata.some(d => d.truck_no == f.truck_no)
				);
				//console.log("ontripnoresult ", JSON.stringify(ontripnoresult))
				
				var primaryontrips = []
				var secondaryontrips = []
				if(ontripnoresult.length > 0)
				{
					await ontripnoresult.map((rd) => {
						if(rd.consigner_code == "F" || rd.consigner_code == "M" 
						|| rd.consigner_code == "G")
						{
							primaryontrips.push(rd)
						}
						else
						{
							secondaryontrips.push(rd)
						}
					})

					await this.setState({
						gpsNotTodayActiveConsignments:ontripnoresult,
						primaryontrips:primaryontrips.length,
						secondaryontrips:secondaryontrips.length
					})
				
					
				}
				else
				{
					await this.setState({
						gpsNotTodayActiveConsignments:[],
						primaryontrips:0,
						secondaryontrips:0
					})
				}
				//console.log("onshow ontripnoresult ", ontripnoresult.length)
			}

			var alldealertrucks = this.state.alldealertrucks
			var atdealernogps = this.state.atdealernogps

			var atdealernogpsNA =await alldealertrucks.filter(f =>
				atdealernogps.some(d => d.truck_no == f.truck_no)
			);
			await this.setState({
				rowdataatdealer:atdealernogpsNA
			})
			//console.log("atdealernogpsNA ", atdealernogpsNA)
		}
		catch(e){

		}
		//console.log("trucksWithGpsLocation ",this.state.trucksWithGpsLocation)
		//console.log("uniqueActiveConsignments ",this.state.totalTrucks)
		


		// var difftrucks = []
		// var result =await totalTrucks.filter(f =>
		// 	!trucksWithGpsLocation.some(d => d.truck_no == f.truck_no)
		//   );
		// //console.log("difftrucks ", result)
		// if(result.length > 0)
		// {

		// 	await this.setState({
		// 		rowwithoutData:result
		// 	});
		// }
		// else
		// {

		// 	await this.setState({
		// 		rowwithoutData:[]
		// 	});
		// }

		
	}
	
	/*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e){
		this.setState({
			sliderTranslate:""
		});
		//console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						truckInfo:truckrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

				/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						consignments:consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			/*Trucks details by Truck No*/
			await redirectURL.post('/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}

		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = ComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/transporterByName', layoutdata1)
				.then((response) => {
					//console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					//console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = ComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						//console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
	} 
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	}

	onClickShowTab = async(tab) =>{
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onClickShowRailTab = async(tab) => {
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

  onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		const statedeptcode = this.state.deptvalue;
		//console.log("statedeptcode ",statedeptcode)
		var columnwithDefs= [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			},
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
		  },  	        
		  {
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
		  },
		// 	{
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
		//   },
		  {
			  headerName: "Last Known City/Town",
			  field: "area",
			  width: 200,
		  },
		  {
			headerName: "Last Known State",
			field: "state",
			width: 200,
		  },
			{
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  type : "datetime",
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			//   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  type : "datetime",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
			 
			 
		  ];
		var columnwithoutDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
				
		  },
		// 	{
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
				
		//   },
		//   {
		// 	headerName: "Actual LSP Provider",
		// 	field: "actual_lspuser",
		// 	width: 200,
				
		//   },
		  {
			  headerName: "Last Known City/Town",
			  field: "area",
			  width: 200,
		  },
		  {
			headerName: "Last Known State",
			field: "state",
			width: 200,
		  },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  type : "datetime",
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			 
		  }
		  
	  ];
		var columnGpsNotTodayDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 140,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			//   valueGetter:function(params) {
			// 	  let transporter_name = null;
			// 	  if (params.data.transporter) {
			// 		  if (params.data.transporter.transporter_name) {
			// 			  transporter_name = params.data.transporter.transporter_name;
			// 		  }
			// 	  }
			// 	  return transporter_name;
			//   }
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200,
				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		//   {
		// 	headerName: "Cluster",
		// 	field: "cluster",
		// 	width: 140
		//   },
		{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		//   {
		// 	  headerName: "Actual LSP Provider",
		// 	  field: "actual_lspuser",
		// 	  width: 200,
			//   valueGetter:function(params){
			// 	  if(params.data.dept_code == 'LOG-SC')
			// 	  {
			// 		return params.data.actual_lspuser;
			// 	  }
			// 	  else
			// 	  {
			// 		let actual_lspuser = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.actual_lspuser) {
			// 				actual_lspuser = params.data.truck.actual_lspuser;
			// 			}
			// 		}
			// 		return actual_lspuser;
			// 	  }
				 
			//   }
		//   },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			// valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.area;
			// 	}
			// 	else
			// 	{
			// 		let area = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.area) {
			// 				area = params.data.truck.area;
			// 			}
			// 		}
			// 		return area;
			// 	}	
			// }
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			//   valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.state;
			// 	}
			// 	else
			// 	{
			// 		let state = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.state) {
			// 				state = params.data.truck.state;
			// 			}
			// 		}
			// 		return state;
			// 	}
			//   }
		  },
		  // {
		  //   headerName: "Last Packet Time",
		  //   field: "timestamp",
		  //   width: 200,
		  //   //cellRenderer:'dateFormater'
		  //   valueGetter:function(params){
		  // 	return getDDMMYYYYHHMMSS(params.data.timestamp)
		  //   }
		  // },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  type : "datetime",
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			 
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  type : "datetime",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			 
		  },
		  // {
		  // 	headerName: "Data Received On",
		  // 	field: "modified_date",
		  // 	width: 200,
		  // 	//cellRenderer:'dateFormater'
		  // 	valueGetter:function(params){
		  // 		return getDDMMYYYYHHMMSS(params.data.modified_date)
		  // 	  }
		  //   }
		  
		  ];
		  var columngpspacketDefs =  [
			  {
				headerName: "< 3 Minutes",
				field: "less_180",
				width: 200,
					
			},  	        
			{
			  headerName: "Between 3 to 5 Minutes",
			  field: "180_300",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 5 to 10 Minutes",
			  field: "300_600",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 10 to 15 Minutes",
			  field: "600_900",
			  width: 200,
				  
			},  	        
			{
			  headerName: "More than 15 Minutes",
			  field: "greater_900",
			  width: 120,
				  
			}
			   
		  ];

		var dealerGpsNotTodayDefs = [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		//   {
		// 	headerName: "Cluster",
		// 	field: "cluster",
		// 	width: 140
		//   },
		// {
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
				
		//   },
		//   {
		// 	  headerName: "Actual LSP Provider",
		// 	  field: "actual_lspuser",
		// 	  width: 200,
		
		//   },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			
		  },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  type : "datetime",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  type : "datetime",
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
		  },
		  
		  ];

    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);
		
        //console.log("Default",window.atob(localStorage.getItem('_t')));
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.transaxis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"With GPS",
	      		      data: this.state.transyaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const transporterNoGPSChart = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.trans_no_gps_axis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"Without GPS",
	      		      data: this.state.trans_no_gps_yaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };

        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const lspNoGPShighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lsp_no_gps_axis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lsp_no_gps_yaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  				  
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.x:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {

							//console.log("test",this.series)

			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        			  
			const highchartgpsinterval = {
				chart: {
					type: 'column'
				},
				title: {
					text: ' '
				},
				xAxis: {
					categories: this.state.gpsintervalaxis
				},
				yAxis: {
					min: 0,
					
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: 'bold',
							color: ( // theme
								Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color
							) || 'gray'
						}
					}
				},
				legend: {
					align: 'right',
					x: -30,
					verticalAlign: 'top',
					y: 25,
					floating: true,
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					borderColor: '#CCC',
					borderWidth: 1,
					shadow: false
				},
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: this.state.gpsintervalyaxis
			};
			
			var israil = (window.location == "/raildefault")?1:0

		const {usermanualmodal} = this.state;
		const modalStyles  = {
		width:'1300px !important',
		}


        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="container-fluid">
					{/* {(this.state.deptvalue == 'SNDG')?
					<div className="row col-xl-12 col-lg-12">
						<div className="row col-xl-12 col-lg-12"> 
							<div className="col-xl-6 col-lg-6"></div>
							<div className="row col-xl-6 col-lg-6">
								<div className="col-xl-7 col-lg-7 f12" style={{textAlign:"right"}}>Master Data Updated: </div>
								<div className="col-xl-5 col-lg-5 f12">Primary - 01-April-2024<br /> Secondary - 12-April-2022</div>
							</div>
							<div className="col-xl-8 col-lg-8"></div>
							<div className="row col-xl-4 col-lg-4 f12 n-p-0">
								<span style={{fontWeight:"bold"}}>Note:&nbsp;</span> Any new trucks taking loads are automatically <br />added to the master data
							</div>
						</div>
					</div>
					:""} */}
                	
                	<div style={{marginBottom:'10px'}} className="row">
					{(this.state.currentDepartmentTitle == 'default')?
					
						<div className="col-xl-12 col-lg-12">
							<div className="card">
							
								<div className="card-header">
									<h5>
										<span>Dashboard  </span>
									</h5>
								</div>

							</div>	
						</div>
						:""}
					{/* {(this.state.deptvalue == 'SNDG')?
					<div className="col-xl-12 col-lg-12">
				
						<ul className="d-tabs">
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "all"?"activet":"")} onClick={this.onClickShowTab.bind(this,'all')}>All</button>
							</li>
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "primary"?"activet":"")} onClick={this.onClickShowTab.bind(this,'primary')}>Primary</button>
							</li>
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "secondary"?"activet":"")} onClick={this.onClickShowTab.bind(this,'secondary')}>Secondary</button>
							</li>
						</ul>
							
					</div>
					:""} */}
					{(window.location == "/raildefault")?
					<div className="col-xl-12 col-lg-12">
				
						<ul className="d-tabs">
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "all"?"activet":"")} onClick={this.onClickShowRailTab.bind(this,'all')}>All</button>
							</li>
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "firstmile"?"activet":"")} onClick={this.onClickShowRailTab.bind(this,'firstmile')}>First Mile</button>
							</li>
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "rakes"?"activet":"")} onClick={this.onClickShowRailTab.bind(this,'rakes')}>Rakes</button>
							</li>
							<li>
								<button className={"btn btn-default "+(this.state.defaulttab == "lastmile"?"activet":"")} onClick={this.onClickShowRailTab.bind(this,'lastmile')}>Last Mile</button>
							</li>
						</ul>
							
					</div>
				:""}
                	
                	</div>


					 
					<DashboardCountersComponent 
					  tottrucks={this.state.truckscnt} 
					  truckgps={this.state.trucklocations} 
					  notruckgps={this.state.notrans} 
					  totalActiveConsignmentsCount={this.state.totalActiveConsignmentsCount} 
					  gpsNotTodayActiveConsignmentsCount={this.state.gpsNotTodayActiveConsignmentsCount} 
					  truckno={this.state.trucknogps}
					  gpsnotreport = {this.state.rowwithoutData.length}
					  ontripwithgps={this.state.ontripwithgps}
					  israil={israil}
					  ontripgpsna={this.state.ontripgpsna}
					  atdealertruckcnt={this.state.atdealertruckcnt}
					  atdealernogps={this.state.atdealernogps.length}
					  primaryontrips={this.state.primaryontrips}
					  secondaryontrips={this.state.secondaryontrips}
					  deptcode={this.state.deptvalue}
					   />
                    <div className="row">
						<div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Trucks with GPS Data</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGrid
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={columnwithDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

	                    
	                    

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGrid
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnwithoutDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowwithoutData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

						<div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>Visibility Report</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGrid
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnwithoutDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowDataForNotReportedFor15days}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>



                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (On Trip Trucks)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGrid
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.gpsNotTodayActiveConsignments}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (At Dealer)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGrid
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={dealerGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowdataatdealer}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

                
	                    
						<div className={"dataLoadpage " +(this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " +(this.state.loadshow)}>
							<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
						</div>
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 	{this.displayData}
				 	</div>


                </div>
             
            </div>
        );
    }
}
	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}

export default Default;


                                
                                
                                
                                
                                
                                