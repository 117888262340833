import React, { Component } from 'react';

 
const Consignmentforceclose = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickForceClose(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
       
            var colr = 'txt-secondary'
      
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-danger custom-btn f12 label label-success" 
            title="Force Close" >Force Close</button>
            
        </div>
    );
};

export default Consignmentforceclose;
