
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';

 
const DateFormater = (props) => {
    var dateis = props.value;
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">
              {dateis}  
            </Moment>
    );
};

export default DateFormater;
