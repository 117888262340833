
import React, { Component } from 'react';

 
const KpiUnLoadingDelayActions = (props) => {
    //console.log("LoadingDelay ",props);
   const handleUnLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowUnLoadingDelayKpi(props);
    };
    const handleNoUnLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowUnLoadingDelayKpi(0);
    }; 
    return (
        <div>
            {(props.data.unloading_delay_exception == 1)?
            <button onClick={handleUnLoadingDelayClick} className="custom-btn f22 label label-success" title="UnLoading Delay" ><i className="icofont icofont-vehicle-delivery-van mildgreen"></i> &nbsp;</button>
            
            :
            <div>
            {((props.data.status == 3) || (props.data.status == 4) || (props.data.status == 5))?
                <button onClick={handleUnLoadingDelayClick} className="custom-btn f22 label label-success" title="UnLoading Delay" style={{color:"rgb(204, 204, 204)"}}><i className="icofont icofont-vehicle-delivery-van"></i> &nbsp;</button>
                :
                <button onClick={handleNoUnLoadingDelayClick} className="custom-btn f22 label label-success" title="UnLoading Delay" style={{color:"rgb(204, 204, 204)"}}><i className="icofont icofont-vehicle-delivery-van"></i> &nbsp;</button>
            }
            </div>
            }
        </div>
    );
};

export default KpiUnLoadingDelayActions;
