import { React, useEffect, useState } from 'react';
// import { columnwithDefs } from "./columnDefs_vehicles";
// import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import EditButtonRenderer from './EditButtonRenderer';
import CSVFileValidator from 'csv-file-validator';

import SweetAlert from 'react-bootstrap-sweetalert';

function TransitTimes() {
    var [rowData, setRowData] = useState([])
    const defaultColDef = {
        resizable: true,
        filter: true,
        sortable: true,
        wrapText: true
    }
    var columnDefs = [
        // {
        //     headerName: "",
        //     field: "_id",
        //     width: 80,
        //     cellRenderer: EditButtonRenderer,
        //     // cellRendererParams: {
        //     //     openEditModal: this.showEditBlock,
        //     // },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        {
            headerName: "Plant Code",
            field: "consigner_code",
            width: 160,
            editable: false
        },
        {
            headerName: "Consigner Type",
            field: "consigner_type",
            width: 160,
            editable: false
        },
        {
            headerName: "Vehicle Transit Time (Days)",
            field: "transit_time",
            width: 200,
            editable: false
        },
        {
            headerName: "Distance (Kms)",
            field: "distance_in_km",
            width: 160,
            editable: false
        },
        {
            headerName: "Consignee City",
            field: "consignee_city",
            width: 160,
            editable: false
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width: 160,
            editable: false
        },
    ]
    // var [showloaderbg, setshowloaderbg] = useState("show-m");
    // var [showloader, setshowloader] = useState("show-m");
    // var [showAddSlider, setshowAddSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    const [showBulkUploadSlider, setShowBulkUploadSlider] = useState("");
    const [file, setFile] = useState('');

    const [csvContent, setCsvContent] = useState([]);
    // const [file, setFile] = useState('');
    // var [sliderText, setSliderText] = useState("");
    // const [isEditMode, setIsEditMode] = useState(false);
    // var [showEditSlider, setshowEditSlider] = useState("");
    // var [is_active, setstatus] = useState("");
    var [show, setShow] = useState(false);
    var [basicType, setBasicType] = useState("");
    var [basicTitle, setBasicTitle] = useState("");
    useEffect(() => {
        setTimeout(() => {
            // setshowloader("show-n")
        }, 2000);
        loadData();
    }, []);



    const loadData = async () => {
        const parameters = {};
        await redirectURL.post('/sndtransittimes', parameters)
            .then(response => {
                if (response.data.status === "success") {
                    setRowData(response.data.records);
                }
                // setshowloader("show-n");
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const showBulkUploadBlock = () => {
        setShowBulkUploadSlider("slider-translate-30p");
        setoverlay("show-m");
    };

    const resetForm = () => {

    };

    const resetUpload = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }

        // setBulkUploadData(null);
    };



    const handleBulkUploadSubmit = async (e) => {
        e.preventDefault();

        if (csvContent.length > 0) {
            try {
                // Get the current date in dd-mm-yyyy format
                const createdDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

                const updatedCsvContent = csvContent.map(item => ({
                    ...item,
                    created_date: createdDate,
                }));

                const response = await redirectURL.post('/bulkUplodtransittimes', updatedCsvContent, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('userid'),
                    },
                });

                if (response.data.message === 'success') {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle("Bulk Upload Successful");
                    setBasicType("success");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
                else if (response.data.status === "partial") {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle(`${response.data.uploadedCount} Records uploaded out of ${response.data.totalRecords}`);
                    setBasicType("warning");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
            } catch (error) {
                console.error('Error during bulk upload:', error);
                setShow(true);
                setBasicTitle('Bulk Upload Error');
                setBasicType('danger');
            }
        } else {
            setShow(true);
            setBasicTitle('Cannot enter empty data');
            setBasicType('danger');
        }
    };



    const hideAll = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }
        setoverlay("show-n");
        setShowBulkUploadSlider("");
    };



    const handleBulkUpload = async (e) => {
        if (e.target.files.length !== 0) {
            const config = {
                headers: [

                    {
                        name: 'consigner_code',
                        inputName: 'consigner_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consigner_type',
                        inputName: 'consigner_type',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'transit_time',
                        inputName: 'transit_time',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'distance_in_km',
                        inputName: 'distance_in_km',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_city',
                        inputName: 'consignee_city',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_code',
                        inputName: 'consignee_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    }

                ],
            };

            // const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- _]*$/); // Allowed characters

            try {
                const csvData = await CSVFileValidator(e.target.files[0], config);
                console.log('csvData', csvData);

                let keys = Object.keys(csvData.data[0]);
                let items = ['consigner_code', 'consigner_type', 'transit_time', 'distance_in_km', 'consignee_city', 'consignee_code'];
                let check = csvData.inValidData.length === 0 && items.every(item => keys.includes(item));

                console.log(check, "check")



                if (!check) {
                    e.target.value = ""; // Clear file input
                    setShow(true);
                    setBasicTitle('Dont upload other fields or special characters');
                    setBasicType('danger');
                } else {
                    setCsvContent(csvData.data);
                }
            } catch (err) {
                console.error('Error validating CSV:', err);
            }

            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = e.target.result;
                console.log(contents);
            };
            reader.readAsText(e.target.files[0]);

            setFile(e.target.files[0]);

            if (e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel') {
                e.target.value = null;
                setShow(true);
                setBasicType('danger');
                setBasicTitle('Please upload file having extensions .csv only.');
            }
        }
    };



    return (

        <div className="container-fluid mt-20p">
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={() => {
                        setShow(false);
                    }}>
                </SweetAlert>
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12 xl-60">
                    <div className="card">
                        <div className="col-lg-12 d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                            <h4 className="screen-name" style={{ marginLeft: '20px' }}>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Transit Times
                            </h4>
                            <button className="btn btn-success ml-2" onClick={showBulkUploadBlock}>
                                <i className="icon-upload"></i>&nbsp;Bulk Upload
                            </button>
                        </div>
                        <div className="card-body">
                            <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={50}
                                    // onGridReady={onGridReadyConsignments}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // frameworkComponents={this.state.frameworkComponents}
                                    enableRangeSelection={true}
                                    enableCellContextMenu={true}
                                    sideBar={{
                                        toolPanels: [
                                            {
                                                id: "columns",
                                                labelDefault: "Columns",
                                                labelKey: "columns",
                                                iconKey: "columns",
                                                toolPanel: "agColumnsToolPanel",
                                                toolPanelParams: {
                                                    suppressPivotMode: true,
                                                    suppressRowGroups: true,
                                                    suppressValues: true,
                                                }
                                            },
                                            {
                                                id: "filters",
                                                labelDefault: "Filters",
                                                labelKey: "filters",
                                                iconKey: "filter",
                                                toolPanel: "agFiltersToolPanel"
                                            }
                                        ]
                                    }}
                                    statusBar={{
                                        statusPanels: [
                                            {
                                                statusPanel: "agTotalAndFilteredRowCountComponent",
                                                align: "left"
                                            },
                                            {
                                                statusPanel: "agTotalRowCountComponent",
                                                align: "center"
                                            },
                                            { statusPanel: "agFilteredRowCountComponent" },
                                            { statusPanel: "agSelectedRowCountComponent" },
                                            { statusPanel: "agAggregationComponent" }
                                        ]
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay Part */}
            <div className={"overlay-part " + overlay} onClick={hideAll}></div>

            {/* Bulk Upload Slider */}
            <div className={`slide-r ${showBulkUploadSlider}`} onClick={(e) => e.stopPropagation()}>
                <div className="slide-r-title">
                    <h4>
                        Bulk Upload Transit Times
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll}>X</span>
                    </h4>
                </div>
                <div className="slide-r-body">
                    <div style={{ padding: "20px" }}>

                        <form method="POST" id="upform" className="theme-form" onSubmit={handleBulkUploadSubmit}>
                            <div className="form-group">
                                <label className="">Upload File</label>
                                <input type="file" id="bulkUploadInput" onChange={handleBulkUpload} className="form-control" required />
                                <div style={{ color: "#ff0000", marginTop: "10px" }}>
                                    {/* ** Allowed Space and Special Characters are <span style={{ fontWeight: "700", fontSize: "18px", fontFamily: "arial" }}>-._,&#</span> */}
                                </div>

                            </div>

                            <div className="form-group">
                                <button className="btn btn-success">Submit</button>
                                <button type="button" onClick={resetUpload} className="btn btn-default">Cancel</button>
                            </div>

                        </form>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../assets/json/bulk_master_transittimes.csv')} target="_blank">Sample Template</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransitTimes;
